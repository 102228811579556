import Slider from "react-slick";
import Cannes from "../images/cannes.png";
import Fwa from "../images/fwa.png";

function OurHistorySlider() {
  var OurHistorySlider = {
    arrows: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <section className="overflow-hidden our-history-slider">
        <div className="oh-slider-main">
          <div className="oh-block-slider">
            <Slider {...OurHistorySlider}>
              <div>
                <div className="oh-block">
                  <h3 className="mb-3">2019</h3>
                  <p className="mb-3">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <img alt="5w1h" width="50" src={Cannes} />
                    <img alt="5w1h" width="100" src={Fwa} />
                  </div>
                </div>
              </div>
              <div>
                <div className="oh-block">
                  <h3 className="mb-3">2019</h3>
                  <p className="mb-3">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <img alt="5w1h" width="50" src={Cannes} />
                    <img alt="5w1h" width="100" src={Fwa} />
                  </div>
                </div>
              </div>
              <div>
                <div className="oh-block">
                  <h3 className="mb-3">2019</h3>
                  <p className="mb-3">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <img alt="5w1h" width="50" src={Cannes} />
                    <img alt="5w1h" width="100" src={Fwa} />
                  </div>
                </div>
              </div>
              <div>
                <div className="oh-block">
                  <h3 className="mb-3">2019</h3>
                  <p className="mb-3">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <img alt="5w1h" width="50" src={Cannes} />
                    <img alt="5w1h" width="100" src={Fwa} />
                  </div>
                </div>
              </div>
              <div>
                <div className="oh-block">
                  <h3 className="mb-3">2019</h3>
                  <p className="mb-3">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <img alt="5w1h" width="50" src={Cannes} />
                    <img alt="5w1h" width="100" src={Fwa} />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OurHistorySlider;
