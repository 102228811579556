import RightArrow from "../images/icons/right-arrow.png";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SP from "../images/sp.svg";
import DND from "../images/dnd.svg";
import MAC from "../images/mac.svg";
import OPS from "../images/ops.svg";

import Slider from "react-slick";

function OurServices1(props) {
  var OurHistorySlider = {
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const [current, setCurrent] = useState("sb-sp");

  return (
		<section className='container section-bottom swp-bottom'>
			{props.title ? (
				<h1 className={props.description ? "sec-title text-center mb-1" : "sec-title text-center mb-5"}>
					{props.title}
				</h1>
			) : null}
			{props.description ? (
				<div className='row justify-content-center'>
					<div className='col-10 col-md-5 col-xl-4'>
						<p className='text-center mb-5'>{props.description}</p>
					</div>
				</div>
			) : null}
			<div className='d-none d-md-block'>
				<div className='row'>
					<div className='col-md-3'>
						<div
							// id="SP"
							className='parent-work-planning sb-sp active'
							onMouseEnter={(e) => {
								console.log(current);
								if (current != "sb-sp") {
									document.querySelector("." + current).className = "parent-work-planning " + current;
									setCurrent("sb-sp");
								}
								document.querySelector(".sb-sp").className = "parent-work-planning sb-sp active";
							}}
							// onMouseLeave={(e) => {
							//   document.querySelector(".sb-sp").className =
							//     "parent-work-planning sb-sp";
							// }}
						>
							<div className='work-logo'>
								<img alt='Strategy & Planning' src={SP} />
							</div>
							<div className='work-planning'>
								<p className='work-strategy'>Brand Strategy</p>
								<p className='work-strategy'>Business Strategy</p>
								<p className='work-strategy'>Innovation Strategy</p>
								<p className='work-strategy'>Customer Experience Strategy</p>
								<p className='work-strategy'>Campaign Planning</p>
								<p className='work-strategy'>Research</p>
							</div>
							<p className='work-title mb-0'>Strategy & Planning</p>
						</div>
					</div>

					<div className='col-md-3'>
						<div
							className='parent-work-planning sb-dd'
							onMouseEnter={(e) => {
								console.log(current);
								if (current != "sb-dd") {
									document.querySelector("." + current).className = "parent-work-planning " + current;
									setCurrent("sb-dd");
								}

								document.querySelector(".sb-dd").className = "parent-work-planning sb-dd active";
							}}
							// onMouseLeave={(e) => {
							//   document.querySelector(".sb-dd").className =
							//     "parent-work-planning sb-dd";
							// }}
						>
							<div className='work-logo'>
								<img alt='Design & Development' src={DND} />
							</div>
							<div className='work-planning'>
								<p className='work-strategy'>Experience Design(UI/UX)</p>
								<p className='work-strategy'>Brand & Design Guidelines</p>
								<p className='work-strategy'>Website & Native Applications</p>
								<p className='work-strategy'>Digital Brand Store</p>
								<p className='work-strategy'>Technology Solutions (AR / VR Phygital)</p>
								<p className='work-strategy'>Gamification</p>
							</div>
							<p className='work-title mb-0'>Design & Development</p>
						</div>
					</div>

					<div className='col-md-3'>
						<div
							className='parent-work-planning sb-mc'
							onMouseEnter={(e) => {
								if (current != "sb-mc") {
									document.querySelector("." + current).className = "parent-work-planning " + current;
									setCurrent("sb-mc");
								}
								document.querySelector(".sb-mc").className = "parent-work-planning sb-mc active";
							}}
							// onMouseLeave={(e) => {
							//   document.querySelector(".sb-mc").className =
							//     "parent-work-planning sb-mc";
							// }}
						>
							<div className='work-logo'>
								<img alt='Media & Commerce' src={MAC} />
							</div>
							<div className='work-planning'>
								<p className='work-strategy'>Media Planning</p>
								<p className='work-strategy'>Ecommerce Management</p>
								<p className='work-strategy'>Performance Marketing</p>
								<p className='work-strategy'>Social & Influencer Marketing</p>
								<p className='work-strategy'>Public Relations</p>
								<p className='work-strategy'>Data & Analytics</p>
							</div>
							<p className='work-title mb-0'>Media & Commerce</p>
						</div>
					</div>

					<div className='col-md-3'>
						<div
							className='parent-work-planning sb-vah'
							onMouseEnter={(e) => {
								if (current != "sb-vah") {
									document.querySelector("." + current).className = "parent-work-planning " + current;
									setCurrent("sb-vah");
								}
								document.querySelector(".sb-vah").className = "parent-work-planning sb-vah active";
							}}
							// onMouseLeave={(e) => {
							//   document.querySelector(".sb-vah").className =
							//     "parent-work-planning sb-vah";
							// }}
						>
							<div className='work-logo'>
								<img alt='Film Making & Operations' src={OPS} />
							</div>
							<div className='work-planning'>
								<p className='work-strategy'>Film Production</p>
								<p className='work-strategy'>Brand & Product Photoshoot</p>
								<p className='work-strategy'>Full Stack Creative Development</p>
								<p className='work-strategy'>Social Media Maganement</p>
								<p className='work-strategy'>Content Management</p>
								<p className='work-strategy'>Vernacular Adoption & Hyperlocal</p>
							</div>
							<p className='work-title mb-0'>Film Making & Operations</p>
						</div>
					</div>
				</div>
			</div>

			<div className='work-plannig-slider d-block d-md-none'>
				<Slider {...OurHistorySlider}>
					<div>
						<div className='parent-work-planning active'>
							<div className='work-logo'>
								<img alt='Strategy & Planning' src={SP} />
							</div>
							<div className='work-planning'>
								<p className='work-strategy'>Brand Strategy</p>
								<p className='work-strategy'>Business Strategy</p>
								<p className='work-strategy'>Innovation Strategy</p>
								<p className='work-strategy'>Customer Experience Strategy</p>
								<p className='work-strategy'>Campaign Planning</p>
								<p className='work-strategy'>Research</p>
							</div>
							<p className='work-title mb-0'>Strategy & Planning</p>
						</div>
					</div>

					<div>
						<div className='parent-work-planning active'>
							<div className='work-logo'>
								<img alt='Design & Development' src={DND} />
							</div>
							<div className='work-planning'>
								<p className='work-strategy'>Experience Design(UI/UX)</p>
								<p className='work-strategy'>Brand & Design Guidelines</p>
								<p className='work-strategy'>Website & Native Applications</p>
								<p className='work-strategy'>Digital Brand Store</p>
								<p className='work-strategy'>Technology Solutions (AR / VR Phygital)</p>
								<p className='work-strategy'>Gamification</p>
							</div>
							<p className='work-title mb-0'>Design & Development</p>
						</div>
					</div>

					<div>
						<div className='parent-work-planning active'>
							<div className='work-logo'>
								<img alt='Media & Commerce' src={MAC} />
							</div>
							<div className='work-planning'>
								<p className='work-strategy'>Media Planing</p>
								<p className='work-strategy'>Ecommerce Management</p>
								<p className='work-strategy'>Performance Marketing</p>
								<p className='work-strategy'>Social & Influencer Marketing</p>
								<p className='work-strategy'>Public Relations</p>
								<p className='work-strategy'>Data & Analytics</p>
							</div>
							<p className='work-title mb-0'>Media & Commerce</p>
						</div>
					</div>

					<div>
						<div className='parent-work-planning active'>
							<div className='work-logo'>
								<img alt='Film Making & Operations' src={OPS} />
							</div>
							<div className='work-planning'>
								<p className='work-strategy'>Film Production</p>
								<p className='work-strategy'>Brand & Product Photoshot</p>
								<p className='work-strategy'>Full Stack Creative Development</p>
								<p className='work-strategy'>Social Media Maganement</p>
								<p className='work-strategy'>Content Management</p>
								<p className='work-strategy'>Vernacular Adoption & Hyperlocal</p>
							</div>
							<p className='work-title mb-0'>Film Making & Operations</p>
						</div>
					</div>
				</Slider>
			</div>
		</section>
	);
}

export default OurServices1;
