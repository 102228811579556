import React, { useState, useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import Header from "../pages/Header";
import Footer from "../pages/Footer";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Header />

          <Component {...props} />
          <Footer />
        </>
      )}
    />
  );
};

export default PublicRoute;
