import BackBanner from "./BackBanner";
import BlogCard from "./BlogCard";
import Share from "../images/icons/share.png";
import LinkedInBlack from "../images/icons/linkedin-black.png";
import FacebookBlack from "../images/icons/facebook-black.png";

function Blog() {
  return (
    <div>
      <BackBanner />

      <section className="container mt-3">
        <div className="row justify-content-center">
          <div className="col-md-3">
            <p style={{ color: "#d5d5d5", fontWeight: "400" }} className="mb-0">
              WRITTEN BY
            </p>
            <p style={{ fontSize: "14px", fontWeight: "700" }} className="mb-0">
              5W1H
            </p>

            <div className="mt-3 mb-3 mb-md-0">
              <div className="blog-icons">
                <div>
                  <a href="">
                    <img src={Share} />
                  </a>
                </div>
                <div className="mx-3">
                  <a href="">
                    <img src={FacebookBlack} />
                  </a>
                </div>
                <div>
                  <a href="">
                    <img src={LinkedInBlack} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div style={{ fontSize: "15px" }} className="type-details mb-3">
              JUNE 4 <span className="mx-3">|</span> TECHNOLOGY{" "}
              <span className="mx-3">|</span>{" "}
              <span className="component-color" style={{ color: "#FA3535" }}>
                4 min read
              </span>
            </div>

            <div className="my-3">
              <h1 className="mb-3">
                Virtual Clothing Is Helping Fashion Brands Dress to Impress
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet
              </p>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
              <p></p>
            </div>

            <div className="mb-3">
              <h5 className="">Lorem ipsum dolor</h5>

              <p>
                Lorem ipsum dolor Lorem ipsum dolor sit amet, consetetur
                sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet.
              </p>

              <p>
                Lorem ipsum dolor Lorem ipsum dolor sit amet, consetetur
                sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet.
              </p>
            </div>

            <div className="mb-3">
              <h5 className="">Lorem ipsum dolor</h5>

              <p>
                Lorem ipsum dolor Lorem ipsum dolor sit amet, consetetur
                sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet.
              </p>
            </div>
          </div>
          <div className="col-md-8">
              <hr className="my-5" />
              <h3 className="mb-5">Recommended Reading</h3>
          </div>
        </div>
      </section>

      <BlogCard />
    </div>
  );
}

export default Blog;
