// import BackBanner from "./BackBanner";
import Link1 from "../images/icons/link.svg";
// import GreyRectangle from "../images/Grey-rectangle.png";
// import PlusBlack from "../images/icons/plus-black.png";
import LatestWork1 from "./LatestWork";
// import LinkedInBlack from "../images/link.png";
// import FacebookBlack from "../images/face.png";
import LinkedInBlack from "../images/icons/linkedin.svg";
import FacebookBlack from "../images/icons/facebook.svg";
import TwitterBlack from "../images/icons/twitter.svg";

import ModalVideo from "react-modal-video";

// import TwitterBlack from "../images/twitter.png";
// import WorkImage from "../images/projects-bg.png";
import ChevronLeft from "../images/icons/chevron-left-solid.svg";
import { Link } from "react-router-dom";
// import ReactHtmlParser from "react-html-parser";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "../axios";
import { BASE_URL } from "../config";

function Project() {
	const [latestWork, setLatestWork] = useState([]);
	const [item, setLatestItem] = useState([]);

	const [innerVideos, setInnerVideos] = useState([]);

	const [recentWork1, setRw1Open] = useState(false);
	const [rworkLink, setRWorkLink] = useState("");

	console.log(latestWork, "latestWork >>>>>>>>>>>>>>>>>>>>");

	const { path } = useParams();

	useEffect(() => {
		if (latestWork?.length > 0) {
			setInnerVideos([
				latestWork[0].inner_video_link1,
				latestWork[0].inner_video_link2,
				latestWork[0].inner_video_link3,
				latestWork[0].inner_video_link4,
				latestWork[0].inner_video_link5,
			]);
		}
	}, [latestWork]);

	useEffect(() => {
		//do something
		setLatestWork([]);

		console.log(path, "props.path");
	}, [path]);

	useEffect(() => {
		if (latestWork.length > 0) {
			return;
		}

		let pathq = "getworkbyid/" + path;
		return axios
			.get(pathq)
			.then((data) => {
				setLatestWork(data.data.data);
				let gotData = data.data.data[0];
				let html = gotData.html;
				console.log("item", gotData.html);

				html = html.split("</p>").join("");
				html = html.split("<p>").join("");
				html = html.split("&lt;").join("<");
				html = html.split("&gt;").join(">");

				// html = html.replace("</p>", "");
				// html = html.replace("<p>", "");

				// html = html.replace("&lt;", "<");
				// html = html.replace("&gt;", ">");

				gotData.html = html;
				console.log("item", gotData.html);
				setLatestItem(gotData);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [latestWork, path]);

	const handleYoutubeVideo = (link) => {
		setRWorkLink(link);
		setRw1Open(true);
	};

	const getVideoId = (link) => {
		console.log(link, "link >>>>>>>>>");
		if (!link) {
			return "";
		}

		let code = null;
		link = link.trim();
		if (link.indexOf("youtube.com") !== -1) {
			let res = link.split("=");
			code = res[1].split("&")[0];
		} else {
			let res = link.split("/");
			code = res[3];
		}
		return code;
	};

	return (
		<div>
			{latestWork.length > 0 ? (
				<>
					<div className='container'>
						<section>
							<div className='back-banner'>
								<img alt={item.project_name} className='img-fluid' src={BASE_URL + "/uploads/" + item.pic_wide} />
								<div className='overlay'>
									<div className='back py-4 d-flex align-items-center'>
										<Link to='/work'>
											<img alt={item.project_name} className='mr-3 chev-left' width='30' src={ChevronLeft} />
										</Link>
									</div>
								</div>
							</div>
						</section>

						<section className='section-project-content'>
							<div className='project-intro-section'>
								<div className='row'>
									<div className='col-md-6'>
										<div className='project-detail-box'>
											<span className='project-type'>{item.client_type}</span>
											<div className='project-name'>
												<div className='pn-title'>{item.project_name}</div>
												<div style={{ fontSize: 15 }}>{item.description}</div>
											</div>
											<span className='client-name'>{item.client_name}</span>
											<hr style={{ border: "1px solid #ffffff" }} />
											<div className='bwc'>
												{item.branding !== "-" ? (
													<>
														{item.branding !== "-" ? item.branding : null} <span className='mx-2'>|</span>
													</>
												) : null}
												{item.website !== "-" ? (
													<>
														{item.branding !== "-" ? item.website : null}
														<span className='mx-2'>|</span>
													</>
												) : null}
												{item.campaigns !== "-" ? item.campaigns : null}
											</div>
											{item.website !== "-" ? (
												<div className='d-flex align-items-center'>
													<Link to={{ pathname: item.website }}>
														<img
															alt={item.project_name}
															style={{ marginRight: "10px" }}
															className='mr-3'
															src={Link1}
															width='15'
														/>
														<span className='project-type'>Project link/Website Link</span>
													</Link>
												</div>
											) : null}
										</div>
									</div>
									<div className='col-md-6 pb-5 py-md-5'>
										{item.context ? (
											<div>
												{" "}
												<h5>Context</h5>
												<p className='mb-4'>{item.context}</p>
											</div>
										) : null}
										{item.solution ? (
											<div>
												<h5>Solution</h5>
												<p className='mb-4'>{item.solution}</p>
											</div>
										) : null}

										{item.result ? (
											<div>
												<h5>Results</h5>
												<p className='mb-4'>{item.result}</p>
											</div>
										) : null}
									</div>
								</div>
							</div>

							{/* SHOWCASE IMAGE SECTION */}

							{latestWork?.length > 0 &&
								latestWork.map((item) => {
									return (
										<div className='project-image-section'>
											<div class='row text-center'>
												{item.inner_video_link4 ? (
													<div class='col-12 my-3 my-md-4'>
														<h4 class='mb-2 mb-md-3'>{item.inner_video_link4}</h4>
														<img
															class='img-fluid auto-margin-class sbsec '
															src={BASE_URL + "/uploads/" + item.inner_video_link4_image}
														/>
													</div>
												) : null}

												{item.inner_video_link5 ? (
													<div class='col-12 my-3 my-md-4'>
														<h4 class='mb-2 mb-md-3'>{item.inner_video_link5}</h4>
														<img
															class='img-fluid auto-margin-class sbsec '
															src={BASE_URL + "/uploads/" + item.inner_video_link5_image}
														/>
													</div>
												) : null}

												{item.inner_image_header ? (
													<div class='col-12 my-3 my-md-4'>
														<h4 class='mb-2 mb-md-3'>{item.inner_image_header}</h4>
														<img
															class='img-fluid auto-margin-class sbsec '
															src={BASE_URL + "/uploads/" + item.inner_image_url}
														/>
													</div>
												) : null}
											</div>
										</div>
									);
								})}

							{/* SHOWCASE VIDEO SECTION */}
							{innerVideos.length > 0 ? (
								<div className='project-video-section'>
									<h1 class='sec-title text-center mt-4'>Showcase</h1>
									<div class='row justify-content-center'>
										{innerVideos[0] ? (
											<div class='col-md-4 mb-4'>
												<a>
													<div
														className='rw-poster'
														onClick={() => {
															handleYoutubeVideo(innerVideos[0]);
														}}>
														<img class='img-fluid' src={BASE_URL + "/uploads/" + item.inner_video_link1_image} />
													</div>
												</a>
											</div>
										) : null}
										{innerVideos[1] ? (
											<div class='col-md-4 mb-4'>
												<a>
													<div
														className='rw-poster'
														onClick={() => {
															handleYoutubeVideo(innerVideos[1]);
														}}>
														<img class='img-fluid' src={BASE_URL + "/uploads/" + item.inner_video_link2_image} />
													</div>
												</a>
											</div>
										) : null}
										{innerVideos[2] ? (
											<div class='col-md-4 mb-4'>
												<a>
													<div
														className='rw-poster'
														onClick={() => {
															handleYoutubeVideo(innerVideos[2]);
														}}>
														<img class='img-fluid' src={BASE_URL + "/uploads/" + item.inner_video_link3_image} />
													</div>
												</a>
											</div>
										) : null}
									</div>
								</div>
							) : null}

							<div>
								<ModalVideo
									autoplay
									isOpen={recentWork1}
									channel='youtube'
									videoId={getVideoId(rworkLink)}
									onClose={() => setRw1Open(false)}
								/>
							</div>
						</section>
					</div>

					<LatestWork1 path={path} />
				</>
			) : (
				<div className='a-loader'>Loading</div>
			)}
		</div>
	);
}

export default Project;
