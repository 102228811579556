// import BgLemon from "../images/bg-lemon.png";
import RightArrow from "../images/icons/right-arrow.png";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import axios from "../axios";
import { BASE_URL } from "../config";

function LatestWorkAll() {
  const [latestWork, setLatestWork] = useState([]);

  
  // const revarr = [...latestWork].reverse()
  
  console.log(latestWork, "latestWork >>>>>>>")
  const [teamshow, setteamshow] = useState(2);
  const [loadMore, setLoadMore] = useState(false)

  // console.log(loadMore, "loadMore>>>>>>>>>>")

  useEffect(() => {
    // if (latestWork.length > 0) {
    //   return;
    // }
    let path = loadMore ?  "getwork?all=true" : "getwork";
    return axios
      .get(path)
      .then((data) => {
        if(loadMore){

        
        
        setLatestWork(data.data.data);
        // console.log(data.data.data, "getwork >>>>>>>>>>>>>>>>>")
        }else{
          setLatestWork(data.data.data.slice(0,6));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ loadMore]);

 

  return (
    <section className="container section-bottom sec-latest-work">
      <div className="row justify-content-center">
        <div className="col-10 col-md-12 col-xl-10 mb-4 mx -md-auto">
          <div class="d-md-flex justify-content-between mb-3 align-items-center">
            <h1 className="text-center sec-title mb-0">Latest Works</h1>
            <div className="d-none d-md-block">
              {/* <Link to="/work">
                <button className="main-button">
                  <div class="wave"></div>
                  <span>
                    View All <img alt="ViewAll" src={RightArrow} />
                  </span>
                </button>
              </Link> */}
              {/* <Dropdown>
                <Dropdown.Toggle className="rm-dropdown" id="dropdown-basic">
                  All Work
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          </div>
        </div>

        {latestWork
        // .reverse()
        .map((item, index) => (
          <div className="col-10 col-md-6 col-xl-5 mt-4 mt-md-4">
            <Link to={"/project/" + item.path}>
              <div className="latest-work-box">
                <img
                  alt={item.project_name}
                  src={BASE_URL + "/uploads/" + item.pic_square}
                  className=""
                />
                <div className="overlay">
                  <div className="content-details d-flex align-items-start flex-column">
                    <small>{item.client_type}</small>
                    <div className="mb-auto">
                      <div className="about-project">{item.project_name}</div>{" "}
                      <div className="rm-lw-content">{item.description}</div>
                    </div>
                    <small> {item.client_name}</small>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
        {
        // Math.round(latestWork.length / 2) > teamshow
        !loadMore 
        ? (
          <div className="mt-3">
            <button
              className="main-button mx-auto"
              onClick={() => {
                setteamshow(teamshow + 1);
                setLoadMore(true)
              }}
            >
              <div class="wave"></div>
              <span>
                Load More <img alt="ViewAll" src={RightArrow} />
              </span>
            </button>
          </div>
        ) : null}
      </div>
    </section>
  );
}

export default LatestWorkAll;
