import RightArrow from "../images/icons/arrow-right-solid.svg";
import BlogCard from "./BlogCard";
import { Link } from "react-router-dom";

function Learn() {
  return (
    <div>
      <section className="container type-space mb-5">
        <div className="design-type type-all">
          <div>
            <a href="" className="type-btn">
              ALL
            </a>
          </div>
          <div>
            <a href="" className="type-btn">
              DESIGN
            </a>
          </div>
          <div>
            <a href="" className="type-btn">
              TECHNOLOGY
            </a>
          </div>
          <div>
            <a href="" className="type-btn">
              DESIGN PSYCHOLOGY
            </a>
          </div>
          <div>
            <a href="" className="type-btn">
              GENERAL
            </a>
          </div>
          <div>
            <a href="" className="type-btn">
              RESOURCES
            </a>
          </div>
        </div>
      </section>

      <section className="container d-none d-md-block section-bottom position-relative banner-description">
        <Link to="/blog">
        <div className="learn-banner" >
          <div className="content-banner">
            <div className="type-details">
              JUNE 4 <span className="mx-3">|</span> TECHNOLOGY{" "}
              <span className="mx-3">|</span>{" "}
              <span className="component-color" style={{ color: "#FA3535" }}>4 min read</span>
            </div>
            <div className="type-title my-3">
              Virtual Clothing Is Helping Fashion Brands Dress to Impress
            </div>
            <p className="mb-0 type-summary">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet
            </p>
            <div className="learn-right-arrow mt-4">
              <img width="25" src={RightArrow} />
            </div>
          </div>
        </div></Link>
      </section>

      <BlogCard />

      </div>
  );
}

export default Learn;
