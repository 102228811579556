import ontime from "../images/ontime.png";
import nofalsepromises from "../images/nofalsepromises.png";
import learnunlearn from "../images/learnunlearn.png";
import celebrateefforts from "../images/celebrateefforts.png";
import respectforall from "../images/respectforall.png";
import classyindefeat from "../images/classyindefeat.png";
function OurValues() {
  return (
    <section className="container section-bottom sb-they-trust-us">
      <h1 className="text-center sec-title mb-5">Our Values</h1>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <div className="row justify-content-center">
            <div className="col-6 col-md-4 text-center col-xl-4 mb-4">
              {/* <div style={{}}> */}
              <img
                className="img-fluid our-values-img"
                src={ontime}
                alt="On Time Every Time"
              />
              <h3 className="mt-3" style={{ fontWeight: "400", fontSize: 24 }}>
                On Time <br />
                Every Time
              </h3>
              {/* </div> */}
            </div>

            <div className="col-6 col-md-4 text-center col-xl-4 mb-4">
              <img
                className="img-fluid our-values-img"
                src={nofalsepromises}
                alt="On Time Every Time"
              />
              <h3 className="mt-3" style={{ fontWeight: "400", fontSize: 24 }}>
                No False
                <br />
                Promises
              </h3>
            </div>

            <div className="col-6 col-md-4 text-center col-xl-4 mb-4">
              <img
                className="img-fluid our-values-img"
                src={learnunlearn}
                alt="On Time Every Time"
              />
              <h3 className="mt-3" style={{ fontWeight: "400", fontSize: 24 }}>
                Learn &<br />
                Unlearn
              </h3>
            </div>

            <div className="col-6 col-md-4 text-center col-xl-4 mb-4">
              <img
                className="img-fluid our-values-img"
                src={celebrateefforts}
                alt="On Time Every Time"
              />
              <h3 className="mt-3" style={{ fontWeight: "400", fontSize: 24 }}>
                Celebrate
                <br />
                Efforts
              </h3>
            </div>

            <div className="col-6 col-md-4 text-center col-xl-4 mb-4">
              <img
                className="img-fluid our-values-img"
                src={respectforall}
                alt="On Time Every Time"
              />
              <h3 className="mt-3" style={{ fontWeight: "400", fontSize: 24 }}>
                Respect
                <br />
                For All
              </h3>
            </div>

            <div className="col-6 col-md-4 text-center col-xl-4 mb-4">
              <img
                className="img-fluid our-values-img"
                src={classyindefeat}
                alt="On Time Every Time"
              />
              <h3 className="mt-3" style={{ fontWeight: "400", fontSize: 24 }}>
                Classy In
                <br />
                Defeat
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurValues;
