import "./App.css";
import "./Style.css";
import "./Responsive.css";
// import Header from "./pages/Header";
import Home from "./pages/Home";
// import Footer from "./pages/Footer";
import About from "./pages/About";
import Services from "./pages/Services";
import Work from "./pages/Work";
import Learn from "./pages/Learn.js";
import Blog from "./pages/Blog";
import Project from "./pages/Project";
import PublicRoute from "./components/PublicRoute";
import { BrowserRouter, Switch } from "react-router-dom";
import { ThemeProvider } from "./components/ThemeContext";
import ThemeService from "./components/ThemeService";
import ScrollToTop from "./components/ScrollToTop";
import '../node_modules/react-modal-video/scss/modal-video.scss';
function App() {
  return (
    <ThemeProvider>
      <ThemeService>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <PublicRoute restricted={false} component={Home} path="/" exact />
            <PublicRoute
              restricted={false}
              component={About}
              path="/about"
              exact
            />
            <PublicRoute
              restricted={false}
              component={Services}
              path="/services"
              exact
            />
            <PublicRoute
              restricted={false}
              component={Work}
              path="/work"
              exact
            />
            <PublicRoute
              restricted={false}
              component={Learn}
              path="/learn"
              exact
            />
            <PublicRoute
              restricted={false}
              component={Blog}
              path="/blog"
              exact
            />
            <PublicRoute
              restricted={false}
              component={Project}
              path="/project/:path"
              exact
            />
          </Switch>
        </BrowserRouter>
      </ThemeService>
    </ThemeProvider>
  );
}

export default App;
