import React, { createContext, useState } from "react";

export const ThemeContext = createContext();

// This context provider is passed to any component requiring the context
export const ThemeProvider = ({ children }) => {
  const [style, setStyle] = useState(
    window.localStorage.getItem("theme")
      ? window.localStorage.getItem("theme")
      : "App light-theme"
  );

  return (
    <ThemeContext.Provider
      value={{
        style,
        setStyle,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
