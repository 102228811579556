// import RightArrow from "../images/icons/right-arrow.png";
import UppwardSolutions from "../images/uppwardSolutionsone.png";
import RightSolution from "../images/d-solution.png";
// import { Link } from "react-router-dom";

function OurServices() {
  return (
    <section className="container our-services  mb-5">
      <div className="row justify-content-center">
        <div className="col-10 col-md-12 col-xl-10 text-center">
          <h1 className="mb-3 mb-md-4 mb-xl-4 sec-title sbcss">
            Purpose Led Performance
          </h1>
          <p className="d-none d-md-block mb-5 ws ourservicestitlep">
            We look inward to solve brand problems and outward to solve{" "}
            <br></br>
            communication issues. Our services are driven by keeping the{" "}
            <br></br> brand purpose at the core. We use creative communications{" "}
            <br></br> and simple solutions to drive business growth.
          </p>
          <p className="d-block d-md-none ws">
            We look inward to solve brand problems and outward to solve
            communication issues. Our services are driven by keeping the brand
            purpose at the core. We use creative communications and simple
            solutions to drive business growth.
          </p>
        </div>

        <div className="col-6 d-none d-md-block col-md-5 text-center ourservicestitlepin">
          INWARD SOLUTIONS
        </div>
        <div className="col-6 d-none d-md-block col-md-5 text-center ourservicestitlepin">
          OUTWARD SOLUTIONS
        </div>

        <div className="col-12 col-md-12 col-xl-10 mt-3 d-none d-md-block">
          <div className="position-relative">
            <div className="d-parent-left-chevron">
              <div className="left-chevron bg-theme-black">
                <div className="diagram-text">
                  Branding <br /> & Design
                </div>
              </div>
              <span className="branding-text">
                We work with clients to put words and imagine visuals that
                define the brand purpose. We set creative guidelines for the
                brand universe.
              </span>
              <span className="text-pointer bg-theme-white"></span>
            </div>
            <div className="services-diagram d-flex justify-content-center align-items-center">
              <div className="parent-left-point">
                <img src={RightSolution} className="img-fluid" alt="5w1h" />
                <div className="diagram-text">
                  Brand <br /> Strategy <br /> & Solutions
                </div>
                <span className="branding-text">
                  We develop business strategies for brand growth. We use
                  technology and creative ideas to reimagine a simple and
                  meaningful consumer journey.
                </span>
                <span className="text-pointer bg-theme-white"></span>
              </div>
              <div class="mid-circle bg-theme d-flex justify-content-center align-items-center text-center px-2">
                <div style={{ width: 150 }}>
                  <h6 className="mb-3">Brand Purpose</h6>
                  <div className="bp-content">
                    We define (OKRs) objectives & key results that help
                    understand the business aspirations, brand values and
                    mission statement.
                  </div>
                </div>
              </div>
              <div className="parent-right-point">
                <img src={RightSolution} className="img-fluid" alt="5w1h" />
                <div className="diagram-text">
                  Integrated <br /> Campaigns
                </div>
                <span className="branding-text">
                  We bring the brand purpose to life through impactful work
                  across all communication touchpoints that adds meaning to the
                  lives of customers.
                </span>
                <span className="text-pointer bg-theme-white"></span>
              </div>
            </div>
            <div className="d-parent-right-chevron">
              <div className="right-chevron bg-theme-black">
                <div className="diagram-text">
                  Performance <br /> Marketing
                </div>
              </div>
              <span className="branding-text">
                We combine brand stewardship with data science and execution to
                deliver conversion-focused marketing solutions.
              </span>
              <span className="text-pointer bg-theme-white"></span>
            </div>
          </div>
          {/* <div className="d-none d-md-block">
            <Link to="/services">
              <button className="main-button mx-auto">
                <div class="wave"></div>
                <span>
                  View All <img src={RightArrow} />
                </span>
              </button>
            </Link>
          </div> */}
        </div>

        <div className="col-12 col-md-12 d-block d-md-none col-xl-10 my-3">
          <div className="services-diagram d-flex position-relative flex-column justify-align-center align-items-center">
            {/* <div className="left-point bg-theme-black"> */}
            <div className="up-img bg-theme-black">
              <img src={UppwardSolutions} className="img-fluid" alt="5w1h" />
              <div className="diagram-text bg-theme-black">
                INWARD <br /> & SOLUTIONS
              </div>
            </div>
            <div class="mid-circle bg-theme d-flex justify-content-center align-items-center text-center px-2">
              <div>
                <h3 className="mb-0">Brand Purpose</h3>
              </div>
            </div>
            {/* <div className="right-point bg-theme-black"> */}
            <div className="down-img bg-theme-black">
              <img src={UppwardSolutions} className="img-fluid" alt="5w1h" />
              <div className="diagram-text">
                OUTWARD <br /> SOLUTIONS
              </div>
            </div>
          </div>
        </div>

        <div className="d-block d-md-none mt-4">
          <div className="row justify-content-center">
            <div className="col-10 col-md-12 col-xl-10">
              <h4 className="mb-2">Branding Design</h4>
              <p className="mb-4">
                We work with clients to put words and imagine visuals that
                define the brand purpose. We set creative guidelines for the
                brand univere.
              </p>

              <h4 className="mb-2">Brand Strategy & Solutions</h4>
              <p className="mb-4">
                We work with clients to put words and imagine visuals that
                define the brand purpose. We set creative guidelines for the
                brand univere.
              </p>

              <hr className="my-3" />

              <h4 className="mb-2">Integrated Campaigns</h4>
              <p className="mb-4">
                We work with clients to put words and imagine visuals that
                define the brand purpose. We set creative guidelines for the
                brand univere.
              </p>

              <h4 className="mb-2">Performance Marketing</h4>
              <p className="mb-4">
                We work with clients to put words and imagine visuals that
                define the brand purpose. We set creative guidelines for the
                brand univere.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurServices;
