import ourChallangeSliderImage from "../images/our-challange.png";
import AboutSlider1 from "../images/our-challange.png";
import ourBeliefSliderImage from "../images/our-belief.png";
import Slider from "react-slick";
import { useState } from "react";

function OurChallengeSlider() {
	// const [scount, setScount] = useState("01");
	const [title, setTitle] = useState("Our Challenge");

	var ourchallengeslider = {
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 767,
				settings: "unslick",
			},
		],
	};

	return (
		<div>
			<section className='container section-bottom'>
				<div className='row justify-content-center'>
					<div className='our-challenge-slider col-12 col-md-12 col-xl-10'>
						<div className='d-flex align-items-center justify-content-center justify-content-md-start mb-4'>
							<h1 className='mb-0 oc-slider-title'>{title}</h1>
						</div>
						<Slider
							{...ourchallengeslider}
							afterChange={(currentSlide) => {
								if (currentSlide === 0) {
									setTitle("Our Challenge");
								} else {
									setTitle("Our Belief");
								}
							}}>
							{/* SLIDE 01 */}
							<div className='our-challenge-main'>
								<div className='row'>
									<div className='col-lg-6 text-center'>
										<img src={ourChallangeSliderImage} className='slider-img img-fluid' alt='People hate advertising' />
									</div>
									<div className='col-lg-6 align-self-center'>
										<header className='slide-header'>
											<span>People Hate</span>
											<span>Advertising</span>
										</header>
										<div className='slide-content'>
											<p>
												We are a generation with too much data & very little compassion. We use data ethically, not to
												decode people but to understand them.
											</p>
											<p>
												Psychology proves that 100% of humans, take 97% of decisions using feelings not just reasoning.
											</p>
											<strong>It is logic that drives us, but magic that makes us believe.</strong>
										</div>
									</div>
								</div>
							</div>

							{/* SLIDE 02 */}
							<div className='our-challenge-main'>
								<div className='row'>
									<div className='col-lg-6 text-center'>
										<h1 className='oc-slider-title text-center my-4 d-md-none'>Our Belief</h1>
										<img src={ourBeliefSliderImage} className='slider-img img-fluid' alt='Good ideas are simple' />
									</div>
									<div className='col-lg-6 align-self-center'>
										<header className='slide-header'>
											<span>Good Ideas</span>
											<span>Are Simple</span>
										</header>
										<div className='slide-content'>
											<p>
												Advertising is a powerful tool often misused by marketers to sell regressive solutions for
												progressive problems.
											</p>
											<p>We are solution thinkers who believe that Less Is More.</p>
											<strong>We choose simplicity to make advertising more Human and solutions more intuitive.</strong>
										</div>
									</div>
								</div>
							</div>
						</Slider>
					</div>
				</div>
			</section>
		</div>
	);
}

export default OurChallengeSlider;
