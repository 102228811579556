import React, { useState, useEffect } from "react";
import Slider from "react-slick";
// import Ticker from "react-ticker";
import axios from "../axios";

// const MoveStuffAround = () => (
//  <Ticker>
//     {({ index }) => (
//       <>
//         <p className="component-color mb-0">
//           This is the Headline of element #{index}!
//         </p>
//         {/* <img src="www.my-image-source.com/" alt="" /> */}
//       </>
//     )}
//   </Ticker>
// );

function MoveStuffAround(props) {
  let contentArr = [
    {
      content:
        "The important thing is not to stop questioning. Curiosity has its own reason for existence.",
      author: "~ Albert Einstein",
    },
    {
      content:
        "I had six honest serving men-they taught me all I knew: Their names were Where and What and When-and Why and How and Who.",
      author: "~ Rudyard Kipling",
    },
    {
      content:
        "Perhaps it is good to have a beautiful mind, but an even greater gift is to discover a beautiful heart!",
      author: "~ John Nash",
    },
    {
      content:
        "There are three things that I need each day. One, I need something to look up to, another to look forward to, and another is someone to chase.",
      author: "~ Matthew McConaughey",
    },
    {
      content:
        "There’s no shortage of remarkable ideas, what’s missing is the will to execute them.",
      author: "~ Seth Godin",
    },
    {
      content:
        "If something's important enough, you should try. Even if - the probable outcome is failure.",
      author: "~ Elon Musk",
    },
    {
      content:
        "Good copy can't be written with tongue in cheek, written just for a living. You've got to believe in the product.",
      author: "~ David Ogilvy",
    },
    {
      content: "In a gentle way, you can shake the world.",
      author: "~ Mahatma Gandhi",
    },
    {
      content:
        "Advertising is fundamentally persuasion and persuasion happens to be not a science, but an art.",
      author: "~ William Bernbach",
    },
    {
      content: "Be Educated, Be Organized and Be Agitated.",
      author: "~ Dr. B. R. Ambedkar",
    },
    {
      content:
        "I don't believe in taking right decisions. I take decisions and then make them right.",
      author: "~ Ratan Tata",
    },
    {
      content: "Lead from the back – and let others believe they are in front.",
      author: "~ Nelson Mandela",
    },
  ];
  var Quotes = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // const [list, setList] = useState([]);
  // let [quotes, setquotes] = useState([]);

  // useEffect(() => {
  //   if (quotes.length > 0) {
  //     return;
  //   }
  //   let path = "randomquotes";
  //   return axios
  //     .get(path)
  //     .then((data) => {
  //       setquotes(data.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setquotes([
  //         {
  //           quotes: "“ The purpose of our lives is to be happy.” — Dalai Lama.",
  //         },
  //         { quotes: "“ Get busy living or get busy dying.” — Stephen King." },
  //       ]);
  //     });
  // }, [quotes]);

  return (
		<section className='section-bottom random-content'>
			<div className='random-text'>
				<div className='text'></div>
				<div>
					<Slider {...Quotes}>
						{contentArr.map((item, index) => (
							<div>
								<div className='quotes'>
									<p className='mb-0'>
										"{item.content}"
										<br />
										{item.author}
									</p>
								</div>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</section>
	);
}

export default MoveStuffAround;
