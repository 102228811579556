import Slider from "react-slick";
import Comma from "../images/icons/comma.svg";
import { ThemeContext } from "./ThemeContext";
import CommaBlue from "../images/comma-blue.png";
import React, { useContext, useState, useEffect } from "react";
import axios from "../axios";
import ReactHtmlParser from "react-html-parser";

function ClientSlider(props) {
  var clientSlider = {
    centerMode: true,
    arrows: true,
    centerPadding: "60px",
    slidesToShow: 1.6,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const [scount, setScount] = useState(1);
  const [clientsays, setclientsays] = useState([]);
  const user = useContext(ThemeContext);

  useEffect(() => {
    if (clientsays.length > 0) {
      return;
    }
    let path = "getclientsays";
    return axios
      .get(path)
      .then((data) => {
        setclientsays(data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {clientsays.length > 0 ? (
        <section className="section-bottom pb-3 client-say overflow-hidden">
          <h1 className="text-center sec-title mb-5">
            {props.title ? props.title : ""}
          </h1>

          <div className="client-slider mx-4 mx-md-0">
            <Slider
              {...clientSlider}
              afterChange={(currentSlide) => {
                console.log(currentSlide, "currentSlide");
                setScount(currentSlide + 1);
              }}
            >
              {clientsays.map((item, index) => (
                <div key={index}>
                  <div className="slider-box d-flex align-content-between flex-wrap">
                    <div>
                      <p className="sbwrapme">
                        {ReactHtmlParser(item.client_says)}
                      </p>
                    </div>

                    <div className="d-flex align-content-between">
                      <div className="mr-3">
                        {user.style === "App light-theme" ? (
                          <img alt="5w1h" className="img-fluid" src={Comma} />
                        ) : (
                          <img
                            alt="5w1h"
                            className="img-fluid"
                            src={CommaBlue}
                          />
                        )}
                      </div>
                      <div className="ms-3 d-flex align-content-end flex-wrap">
                        <div className="client-post">
                          <h4>{item.client_name}</h4>
                          <small style={{ color: "grey" }}>
                            {item.client_designation}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <div className="text-center mt-2 mt-md-3">
              <span style={{ fontWeight: "500" }}>
                {" "}
                {scount < 9 ? "0" : ""}
                {scount} / {clientsays.length < 9 ? "0" : ""}
                {clientsays.length}{" "}
              </span>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}

export default ClientSlider;
