import Slider from "react-slick";
// import WorkImage from "../images/projects-bg.png";
import RightArrow from "../images/icons/right-arrow.png";

import { Link } from "react-router-dom";

// import { Modal, Button } from "react-bootstrap";

import { useState, useEffect } from "react";

import axios from "../axios";
import { BASE_URL } from "../config";

function WorkShowcaseSlider() {
  const [latestWork, setLatestWork] = useState([]);
  // const [open, onCloseModal] = useState(false);

  useEffect(() => {
    if (latestWork.length > 0) {
      return;
    }
    let path = "getwork";
    return axios
      .get(path)
      .then((data) => {
        setLatestWork(data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [latestWork]);

  var workShowcaseSlider = {
    // centerMode: true,
    arrows: true,
    // centerPadding: "60px",
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [scount, setScount] = useState(1);

  return (
    <div>
      <section className="container sec-work-showcase section-bottom overflow-hidden">
        <div className="row justify-content-center">
          <div className="col-11 col-md-12 col-xl-10">
            <div class="d-md-flex justify-content-between mb-5 align-items-center">
              <h1 className="text-center sec-title mb-0">Latest Works</h1>
              <div className="d-none d-md-block">
                <Link to="/work">
                  <button className="main-button">
                    <div class="wave"></div>
                    <span>
                      View All <img alt="viewall" src={RightArrow} />
                    </span>
                  </button>
                </Link>
              </div>
            </div>
            <div className="work-showcase-slider">
              <Slider
                {...workShowcaseSlider}
                afterChange={(currentSlide) => {
                  console.log(currentSlide, "currentSlide");
                  setScount(currentSlide + 1);
                }}
              >
                {latestWork
                .map((item, index) => (
                  <div key={index}>
                    <Link to={"/project/" + item.path}>
                      <div className="work-block">
                        <img
                          className="img-fluid"
                          alt={item.project_name}
                          src={BASE_URL + "/uploads/" + item.pic_wide}
                        />
                        <div className="overlay">
                          <div className="content-details d-flex align-items-start flex-column">
                            <small>{item.client_type}</small>
                            <div className="mb-auto about-project">
                              <div className="about-project">
                                {item.project_name}
                              </div>
                              <div className="rm-lw-content">
                                {item.description}
                              </div>
                            </div>
                            <small>{item.client_name}</small>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="text-center mt-3 mb-3">
              <span style={{ fontWeight: "500" }}>
                {scount < 9 ? "0" : ""}
                {scount} / {latestWork.length < 9 ? "0" : ""}
                {latestWork.length}{" "}
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WorkShowcaseSlider;
