import React, { useState, useEffect } from "react";
import Day from "../images/icons/dayicon.svg";
import Night from "../images/icons/nighticon.svg";
import { func, string } from "prop-types";
// import DarkModeToggle from "react-dark-mode-toggle";

const Toggle = ({ theme, toggleTheme }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <div className="img-dark-light">
      {isDarkMode ? (
        <div className="mode-toggle">
          <img
            alt="light"
            className="toggle-img"
            src={Day}
            onClick={() => {
              setIsDarkMode(false);
              toggleTheme(false);
            }}
          />
        </div>
      ) : (
        <div className="mode-toggle">
          <div>
            <img
              alt="dark"
              className="toggle-img"
              src={Night}
              onClick={() => {
                setIsDarkMode(true);
                toggleTheme(true);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
Toggle.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
};
export default Toggle;
