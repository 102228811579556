import axios from "axios";
// import LocalStorageService from "./services/storage/LocalStorageService";
// import router from "./router/router";
import { BASE_URL } from "./config";
// LocalstorageService

const instance = axios.create({
  // .. where we make our configurations
  baseURL: BASE_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // const token = localStorageService.getAccessToken();
    // if (token) {
    //   config.headers["x-access-token"] = token;
    // }

    // config.headers["x-access-language"] = localStorage.getItem("language");
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
