import LatestWorkAll from "./LatestWorkAll";
import ClientSlider from "../components/ClientSlider";
// import ProjectBg from "../images/projects-bg.png";
import PlayButton from "../images/icons/play-button.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useState, useEffect } from "react";
import axios from "../axios";
import { BASE_URL } from "../config";
import { Link } from "react-router-dom";
// import ReactDOM from "react-dom";
import ModalVideo from "react-modal-video";

import TrustSlider from "../components/TrustSlider";

function Work() {
	const [isOpen, setOpen] = useState(false);
	const [vURL, setvURL] = useState("");

	const [team, setTeam] = useState([]);
	const [single, setSingle] = useState([]);

	// console.log(single, "single >>>>>>>>>>>>>>>>>>")

	useEffect(() => {
		if (team.length > 0) {
			return;
		}
		let path = "getclient";
		return axios
			.get(path)
			.then((data) => {
				setTeam(data.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [team]);

	useEffect(() => {
		if (single.length > 0) {
			return;
		}
		let path = "getworkone";
		return axios
			.get(path)
			.then((data) => {
				// console.log(data, "data >>>>>>>>")
				setSingle(data.data.data);
				let sin = data.data.data;
				let tUrl = sin[0].reel;
				let arr = tUrl.split("/");
				if (arr.length > 1) {
					tUrl = arr[arr.length - 1];
				} else {
					tUrl = arr[0];
				}
				console.log("-" + tUrl + "-");
				setvURL(tUrl.trim());
			})
			.catch((error) => {
				console.log(error);
			});
	}, [single]);

	return (
		<div>
			{single.length > 0 ? (
				<section className='container project-banner project-section-margin'>
					<div className='row justify-content-center align-items-center mx-0'>
						<div className='col-md-12 col-xl-10'>
							<div className='projects-sec'>
								<h1 className='mb-3'>Projects</h1>
								<img
									className='d-none d-md-block project-banner'
									src={BASE_URL + "/uploads/" + single[0].pic_wide}
									alt={single[0].project_name}
								/>
								<img
									className='d-block d-md-none project-banner'
									src={BASE_URL + "/uploads/" + single[0].pic_square}
									alt={single[0].project_name}
								/>
								{single[0].reel !== "-" ? (
									<div className='video-popup'>
										<ModalVideo
											channel='youtube'
											autoplay
											isOpen={isOpen}
											videoId={vURL}
											onClose={() => setOpen(false)}
										/>
										<Link
											onClick={() => {
												setOpen(true);
											}}
											className='d-flex align-items-center'>
											<img className='project-img' src={PlayButton} alt='Play Button' />
											<div className='play-showreel'>Play Showreel</div>
										</Link>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</section>
			) : null}
			<LatestWorkAll />

			<TrustSlider title='Our Clients' />

			<ClientSlider />
		</div>
	);
}

export default Work;
