// import Banner from "./Banner";
import OurChallengeSlider from "./OurChallengeSlider";
import GreyRectangle from "../images/Grey-rectangle.png";
import CreativeCompany from "../images/creative-company.png";
// import LinkedIn from "../images/icons/linkedin-brands.svg";
// import linkCol from "../images/icons/linkedin.png";
import Plus from "../images/icons/plus.png";
// import CancelWhite from "../images/icons/cancel-white.png";
import { Dropdown } from "react-bootstrap";
// import Mail from "../images/icons/gmail.png";
import OurHistorySlider from "./OurHistorySlider";
// import TopContentBanner from "./TopContentBanner";
import OurValues from "../components/OurValues";

import { Link } from "react-router-dom";

import lsb from "../images/icons/004-linkedin.svg";
import gsb from "../images/icons/email.svg";
import wsb from "../images/icons/Whataspp.svg";

import EllipsisText from "react-ellipsis-text";

import React, { useState, useEffect } from "react";
import axios from "../axios";
import { BASE_URL } from "../config";
// import { BASE_URL } from "../config";

function About() {
  const [awards, setAwards] = useState([]);
  const [team, setTeam] = useState([]);
  const [values, setValues] = useState([]);

  function chunkArray(arr, size, max) {
    console.log(max);
    var groupedArray = [];
    for (let i = 0; i < arr.length; i += size) {
      groupedArray.push(arr.slice(i, i + size));
    }
    let arr1 = [];

    for (let i = 0; i < groupedArray.length; i++) {
      console.log(i, "i", groupedArray.length);
      if (i < max) {
        console.log("In");
        arr1.push(groupedArray[i]);
      }
    }
    console.log(arr1);
    return arr1;
  }

  useEffect(() => {
    if (values.length > 0) {
      return;
    }
    let path = "getvalues";
    return axios
      .get(path)
      .then((data) => {
        setValues(data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [values]);

  useEffect(() => {
    if (team.length > 0) {
      return;
    }
    let path = "getteam";
    return axios
      .get(path)
      .then((data) => {
        setTeam(data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [team]);

  // useEffect(() => {
  //   if (awards.length > 0) {
  //     return;
  //   }
  //   let path = "getawards";
  //   return axios
  //     .get(path)
  //     .then((data) => {
  //       setAwards(data.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [awards]);

  const [member_name, setMName] = useState("");
  const [member_desg, setMDesg] = useState("");
  const [member_desc, setMDesc] = useState("");
  const [member_strength, setMStrength] = useState([]);
  const [member_mail, setMMail] = useState(
    "mailto:max.mustermann@example.com?body=My custom mail body"
  );
  const [member_what, setMWhat] = useState(
    "whatsapp://send?abid=9892511533&text=Hello%2C%20World!"
  );

  const [member_link, setMLink] = useState("");
  const [member_picture, setMpicture] = useState("");
  const [member_word1, setMWord1] = useState("");
  const [member_word2, setMWord2] = useState("");
  const [teamshow, setteamshow] = useState(2);
  // const [counter, setcounter] = useState(0);

  function membereClicked(e, data) {
    var index = 0;
    for (var i = 0; i < team.length; i++) {
      console.log(team[i]);
      if (team[i].team_id == data.team_id) {
        index = i;
      }
    }
    console.log(data, "inneritem");
    const member = team[index];
    console.log(member);
    setMName(member.team_name);
    setMDesg(member.designation);
    setMDesc(member.describe);

    setMMail("mailto:" + member.mail + "?body=My custom mail body");
    setMWhat(
      "whatsapp://send?abid=" + member.whatapps + "&text=Hello%2C%20World!"
    );
    setMLink(member.linkedin);
    setMpicture(BASE_URL + "/uploads/" + member.picture);
    setMWord1(member.word1);
    setMWord2(member.word2);
    setMStrength(member.strength);
  }

  return (
		<div className='about-sb'>
			{/* <Banner /> */}

			{/* Start Model */}

			<div
				className='modal fade team-member-modal'
				id='teamMember'
				tabIndex='-1'
				aria-labelledby='teamMemberLabel'
				aria-hidden='true'>
				<div className='modal-dialog modal-fullscreen'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title' id='teamMemberLabel'>
								Modal title
							</h5>
							<button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
						</div>
						<div className='modal-body p-0'>
							<div className='container h-100'>
								<div className='row justify-content-center h-100'>
									<div className='col-md-6 mt-5 mt-md-0 order-1 order-md-0 d-flex align-items-center'>
										<div className='tm-c-width'>
											<div className='d-none d-md-block'>
												<h4>{member_name}</h4>
												{/* <div className="member-name mb-2">{member_name}</div> */}
												<div className='occupation ws'>{member_desg}</div>
												<div className='occupation1'>{member_desc}</div>
											</div>
											<div className='d-none d-md-block'>
												<div class='sbrow'>
													<div class='sbcolumn'>
														{/* <a href={member_mail}> */}
														<a href={member_mail} target='_blank'>
															<img class='img-responsive' src={gsb} alt='Mail' />
														</a>
													</div>
													{member_what != "-" ? (
														<div class='sbcolumn'>
															{/* <a href={member_what}> */}
															{/* <Link
                                to={{ path_name: member_what }}
                                target="_blank"
                              > */}
															<a href={member_what} target='_blank'>
																<img class='img-responsive' src={wsb} alt='Whatapps' />
															</a>
														</div>
													) : null}
													<div class='sbcolumn'>
														<a href={member_link} target='_blank'>
															<img class='img-responsive' src={lsb} alt='Linkedin' />
														</a>
													</div>
												</div>
											</div>

											<hr className='my-4 d-none d-md-block' />

											<div>
												<ul>
													{member_strength.map((item) => (
														<li>
															<h4>{item.title}</h4>
															<p className='ws'>{item.description}</p>
														</li>
													))}
												</ul>
											</div>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='d-block d-md-none ms-4 ms-md-0'>
											{/* <div className="member-name mb-2">{member_name}</div> */}
											<h4>{member_name}</h4>
											<div className='occupation'>{member_desg}</div>
											<div className='occupation1'>{member_desc}</div>

											<div className='d-block d-md-none'>
												<div class='sbrow'>
													<div class='sbcolumn'>
														<a href={member_mail} target='_blank'>
															<img class='img-responsive' src={gsb} alt='Mail' />
														</a>
													</div>
													<div class='sbcolumn'>
														<a href={member_what} target='_blank'>
															<img class='img-responsive' src={wsb} alt='Whatapps' />
														</a>
													</div>
													<div class='sbcolumn'>
														<a href={member_link} target='_blank'>
															<img class='img-responsive' src={lsb} alt='Linkedin' />
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='team-member-img'>
											<div className='social-icon d-flex flex-wrap justify-content-center mt-3 mt-md-0'>
												<div className='text-center social-icon-responsive'>
													<div>
														<button className='popup-btn me-4 me-md-0' data-bs-dismiss='modal' aria-label='Close'>
															<img alt='CancelButton' src={Plus} />
														</button>
													</div>
												</div>
											</div>
											<img alt={member_name} className='mtb-responsive' src={member_picture} />
											<div className='oc-slogan'>
												<div className='black-content'>{member_word1}</div>
												<div className='red-content'>{member_word2}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* //model End */}

			<div>
				<section className='container-sm top-content-banner tcb-mobile'>
					<div className='text-center'>
						{/* <p className="title-content-banner">Purpose-driven growth.</p> */}

						<p className='para-content-banner'>
							Our purpose is to make people believe in brands, using meaningful ideas and simple solutions.
						</p>
						{/* <p className="para-content-banner-grey">Our approach brings Brand Purpose upstream into the brand’s value proposition, and downstream into its communications.</p> */}
					</div>
				</section>
			</div>

			<section className='container section-bottom creative-company-block'>
				<div className='row justify-content-center mb-5'>
					<div className='col-12 col-md-12 col-xl-10'>
						<img className='creative-company-img img-fluid' src={CreativeCompany} alt='Creative Company Banner' />
					</div>
					<div className='col-11 col-md-6'>
						<div className='text-md-center mt-5'>
							<h5 className='mb-4 ws'>
								Our mission is to add meaning to advertising, by adding meaning to human lives. Our vision is to make
								the world a happier place by solving human problems.
							</h5>
						</div>
					</div>
				</div>
			</section>

			<OurChallengeSlider />
			<OurValues />

			{/*Team */}
			{team.length > 0 ? (
				<section className='container section-bottom meet-the-team'>
					<h1 className='mb-5 mx-auto text-center'>Meet The Team</h1>

					{chunkArray(team, 2, teamshow).map((item, sbindex) => (
						<div className='row mb-4'>
							{item.map((inneritem, index) => (
								<div className='col-md-6 mt-4 mt-md-4'>
									<div className='team-member'>
										<img src={GreyRectangle} className='bg-img' alt='Team Member' />
										{/* <button className="popup-btn">
                 <img src={Plus} />
               </button> */}
										<button
											type='button'
											className='popup-btn'
											data-bs-toggle='modal'
											data-bs-target='#teamMember'
											onClick={(e) => {
												membereClicked(e, inneritem);
											}}>
											<img alt='plus' src={Plus} />
										</button>

										<div className='member-details'>
											<h1>{inneritem.team_name}</h1>
											<p className='occupation ws mb-4 mb-md-0'>{inneritem.designation}</p>
											<p className='member-quotes ws d-none d-md-block'>
												<EllipsisText text={inneritem.describe} length={"200"} />
											</p>
											<div className='d-flex justify-content-center align-items-center'>
												<div className='me-4'>
													<div>
														<Link to={{ pathname: inneritem.linkedin }} target='_blank'>
															<img alt='LinkedIn' className='member-linkedIn' src={lsb} />
														</Link>
													</div>
												</div>
												<div>
													<Link
														to={{
															pathname: "mailto:" + inneritem.mail + "?body=My custom mail body",
														}}
														target='_blank'>
														<img alt='Mail' className='member-linkedIn' src={gsb} />
													</Link>
												</div>
											</div>
										</div>
										<div className='parent-pp'>
											<img
												alt={inneritem.team_name}
												className='profile-picture'
												src={BASE_URL + "/uploads/" + inneritem.picture}
											/>
										</div>
									</div>
								</div>
							))}
						</div>
					))}
					{Math.round(team.length / 2) > teamshow ? (
						<div>
							<div className='col-md-12 mt-5'>
								<button
									className='main-button mx-auto'
									onClick={() => {
										setteamshow(teamshow + 1);
									}}>
									<div className='wave'></div>
									<span>Load More</span>
								</button>
							</div>
						</div>
					) : null}
				</section>
			) : null}
			{/*Awards */}
			{awards.length > 0 ? (
				<section className='container section-bottom about-awards'>
					<div className='row justify-content-center'>
						<div className='col-12 col-md-12 col-xl-10'>
							<div className='d-flex mb-5 align-items-center justify-content-between'>
								<h1 className='sec-title mx-auto text-center'>Awards</h1>
								{/* <div>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      <span style={{ marginRight: "30px" }}>2019</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">2019</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">2020</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">2021</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
							</div>
						</div>
						{awards.map((item) => (
							<div className='col-md-5 mb-4'>
								<div className='awards-rectangle'>
									<div className='d-flex mb-2 align-items-center justify-content-between'>
										<h4 className='font-weight-bold'>Award Name - {item.title}</h4>
										<h4 className='mb-0 font-weight-bold'>{item.awards_no}</h4>
									</div>
									<div className='ws'>
										<p className='mb-1'>Description - {item.description}</p>
										<p>Brand - {item.brand}</p>
									</div>
								</div>
							</div>
						))}
						{/* <div className="col-md-10">
            <button className="main-button mx-auto">
              {" "}
              <div className="wave"></div>
              <span>Load More</span>
            </button>
          </div> */}
					</div>
				</section>
			) : null}
			<section className='section-bottom our-history d-none'>
				<h1 className='text-center mb-5'>Our History</h1>
				<div className='mx-auto mb-4'>
					<Dropdown>
						<Dropdown.Toggle className='rm-dropdown mx-auto' id='dropdown-basic'>
							2019
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item href='#/action-1'>2020</Dropdown.Item>
							<Dropdown.Item href='#/action-2'>2021</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>

				<OurHistorySlider />
			</section>
		</div>
	);
}

export default About;
