import DownArrow from "../images/icons/down-arrow.png";
import DownArrowWhite from "../images/icons/down-arrow-white.png";
import Colorwater from "../images/color-in-water.mp4";
import { ThemeContext } from "../components/ThemeContext";
import React, { useContext } from "react";

function Banner() {
  const user = useContext(ThemeContext);
  return (
    <section className="section-bottom">
      {/* className="rs-video" */}
      <video id="bannerVideo" autoPlay loop muted playsinline>
        <source src={Colorwater} type="video/mp4" />
      </video>

      <canvas className="canvas" style={{ display: "none" }}></canvas>

      <div className="rm-banner-bg">
        <div className="rm-banner-content">
          <div className="banner-gutter">
            <div className="rm-company-title section-title">
              A Creative Media Advertising Agency. 
            </div>
            <p className="mb-0">
              5W1H. Five of the W’s (Who, What, Why, When and Where) and the one
              H (How) is used as a tool to Question Everything. We believe
              asking good questions is the first step towards seeking great
              answers.
            </p>
          </div>
          <div className="d-xl-block sb-mov-top">
            <div
              className="rm-downarrow bg-theme"
              onClick={() => {
                let pageHeight = window.innerHeight;
                window.scrollBy(0, pageHeight);
              }}
            >
              {user.style === "App light-theme" ? (
                <img className="img-fluid" src={DownArrow} />
              ) : (
                <img className="img-fluid" src={DownArrowWhite} />
              )}
              {/* <img width="30" src={DownArrow} /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
