import RightArrow from "../images/icons/arrow-right-solid.svg";
import { Link } from "react-router-dom";

function BlogCard() {
    return (
        <section className="container section-bottom card-section">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-3">
            <Link to="/blog">
            <div className="learn-card-body">
              <div className="card-picture">
                <div className="card-details">
                  <div className="type-details">
                    JUNE 4 <span className="mx-3">|</span> TECHNOLOGY{" "}
                    <span className="mx-3">|</span>{" "}
                    <span className="component-color" style={{ color: "#FA3535" }}>4 min read</span>
                  </div>
                  <div className="type-title my-3">
                    Virtual Clothing Is Helping Fashion Brands Dress to Impress
                  </div>
                  <p className="mb-0 type-summary">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  </p>
                  <div className="learn-right-arrow mt-4">
                    <img width="25" src={RightArrow} />
                  </div>
                </div>
              </div>
            </div></Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-3">
            <div className="learn-card-body">
              <div className="card-picture">
                <div className="card-details">
                  <div className="type-details">
                    JUNE 4 <span className="mx-3">|</span> TECHNOLOGY{" "}
                    <span className="mx-3">|</span>{" "}
                    <span className="component-color" style={{ color: "#FA3535" }}>4 min read</span>
                  </div>
                  <div className="type-title my-3">
                    Virtual Clothing Is Helping Fashion Brands Dress to Impress
                  </div>
                  <p className="mb-0 type-summary">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  </p>
                  <div className="learn-right-arrow mt-4">
                    <img width="25" src={RightArrow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-3">
            <div className="learn-card-body">
              <div className="card-picture">
                <div className="card-details">
                  <div className="type-details">
                    JUNE 4 <span className="mx-3">|</span> TECHNOLOGY{" "}
                    <span className="mx-3">|</span>{" "}
                    <span className="component-color" style={{ color: "#FA3535" }}>4 min read</span>
                  </div>
                  <div className="type-title my-3">
                    Virtual Clothing Is Helping Fashion Brands Dress to Impress
                  </div>
                  <p className="mb-0 type-summary">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  </p>
                  <div className="learn-right-arrow mt-4">
                    <img width="25" src={RightArrow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-3">
            <div className="learn-card-body">
              <div className="card-picture">
                <div className="card-details">
                  <div className="type-details">
                    JUNE 4 <span className="mx-3">|</span> TECHNOLOGY{" "}
                    <span className="mx-3">|</span>{" "}
                    <span className="component-color" style={{ color: "#FA3535" }}>4 min read</span>
                  </div>
                  <div className="type-title my-3">
                    Virtual Clothing Is Helping Fashion Brands Dress to Impress
                  </div>
                  <p className="mb-0 type-summary">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  </p>
                  <div className="learn-right-arrow mt-4">
                    <img width="25" src={RightArrow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-3">
            <div className="learn-card-body">
              <div className="card-picture">
                <div className="card-details">
                  <div className="type-details">
                    JUNE 4 <span className="mx-3">|</span> TECHNOLOGY{" "}
                    <span className="mx-3">|</span>{" "}
                    <span className="component-color" style={{ color: "#FA3535" }}>4 min read</span>
                  </div>
                  <div className="type-title my-3">
                    Virtual Clothing Is Helping Fashion Brands Dress to Impress
                  </div>
                  <p className="mb-0 type-summary">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  </p>
                  <div className="learn-right-arrow mt-4">
                    <img width="25" src={RightArrow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-3">
            <div className="learn-card-body">
              <div className="card-picture">
                <div className="card-details">
                  <div className="type-details">
                    JUNE 4 <span className="mx-3">|</span> TECHNOLOGY{" "}
                    <span className="mx-3">|</span>{" "}
                    <span className="component-color" style={{ color: "#FA3535" }}>4 min read</span>
                  </div>
                  <div className="type-title my-3">
                    Virtual Clothing Is Helping Fashion Brands Dress to Impress
                  </div>
                  <p className="mb-0 type-summary">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  </p>
                  <div className="learn-right-arrow mt-4">
                    <img width="25" src={RightArrow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default BlogCard;