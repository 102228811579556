import { useEffect, useState, useContext } from "react";
import { ThemeContext } from "./ThemeContext";

export const useDarkLightMode = () => {
  const style = useContext(ThemeContext);

  const [theme, setTheme] = useState(window.localStorage.getItem("theme"));
  const setMode = (mode) => {
    console.log(mode);
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };
  const themeToggler = (isDark) => {
    console.log("themeToggler", isDark);
    if (isDark) {
      setMode("App dark-theme");
      style.setStyle("App dark-theme");
    } else {
      setMode("App light-theme");
      style.setStyle("App light-theme");
    }
  };

  useEffect(() => {
    console.log("darkMode");
    const localTheme = window.localStorage.getItem("theme");
    localTheme && setMode(localTheme);
    // localTheme && setTheme(localTheme);
  }, []);
  return [theme, themeToggler];
};
