import RightArrow from "../images/icons/right-arrow.png";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "./Banner";
import LatestWork from "./LatestWork";
// import OurServices from "./OurServices";
import ClientSlider from "../components/ClientSlider";
import { ThemeContext } from "../components/ThemeContext";
import React, { useContext, useState, useEffect } from "react";
import MoveStuffAround from "../components/MoveStuffAround";
import { Link } from "react-router-dom";
import axios from "../axios";
import { BASE_URL } from "../config";
import Slider from "react-slick";
import CreativeCompany from "../images/creative-company.png";
import ModalVideo from "react-modal-video";
import OurServices1 from "../components/OurServices1";
import TrustSlider from "../components/TrustSlider";

function Home() {
	const user = useContext(ThemeContext);
	const [awards, setAwards] = useState([]);
	const [recentWork , setRecentWork] = useState([])
	const [recentWork1, setRw1Open] = useState(false);
	const [rworkLink, setRWorkLink] = useState("")
	// const [recentWork2, rw2Open] = useState(false);
	// const [recentWork3, rw3Open] = useState(false);
	// const [recentWork4, rw4Open] = useState(false);
	// const [recentWork5, rw5Open] = useState(false);


	

	let slickPrev = document.getElementsByClassName("slick-prev");

	useEffect(() => {
		// if (awards.length > 0) {
		// 	return;
		// }

		let path = "getawards";
		return axios
			.get(path)
			.then((data) => {
				setAwards(data.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		// if (recentWork?.length > 0) {
		// 	return;
		// }

		let path = "getrecentwork";
		return axios
			.get(path)
			.then((data) => {
				console.log(data.data, "data >>>>>>>>>>>>>>>>>>>>>>")
				setRecentWork(data.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	var recentWorkSlider = {
		arrows: true,
		slidesToShow: 1,
		centerMode: true,
		focusOnSelect: true,
		centerPadding: "30%",
		cssEase: "cubic-bezier(0.785, 0.135, 0.15, 0.86)",
		responsive: [
			{
				breakpoint: 576,
				settings: {
					centerPadding: "40px",
				},
			},
		],
	};

	const homeRecentWorks = [
		{
			videoID: "T39ZbdGCS48",
			URL: "https://www.youtube.com/watch?v=T39ZbdGCS48",
			title: "How does Rohit Sharma monitor pressure off the field?",
			thumbnail: "https://i.imgur.com/i4e3XBc_d.webp?maxwidth=760&fidelity=grand",
			thumbailAltText: "Alt Data",
		}
	];

	const handleYoutubeVideo = (link) => {
		setRWorkLink(link)
		setRw1Open(true)
	}

	const getVideoId = (link) => {
		console.log(link, "link >>>>>>>>>")
		if(!link) {
			return "";
		}
		
		let code = null;
		link = link.trim();
		if(link.indexOf('youtube.com') !== -1) {
			let res = link.split("=");
			code = res[1].split('&')[0];
		} else {
			let res = link.split('/');
			code = res[3];
		}
		return code;
	}

	console.log(recentWork, "recentWork")

	return (
		<div>
			<Banner />

			{/* <section className='section-bottom sec-two container'>
				<div className='row justify-content-center'>
					<div className='col-10 col-md-7 col-xl-6 mb-3 mb-md-0 d-flex align-items-center'>
						<div className='d-flex align-items-center'>
							<div className='who-we-are'>
								<span className='component-color'>Who</span> <br></br> We Are ?
							</div>
						</div>
					</div>
					<div className='col-10 col-md-5 col-xl-4 wa-content'>
						<p className='mb-4'>
							Ping <br />
							Ping <br />
							Ping <br />
							Welcome to Advertising!
						</p>
						<p className='mb-4'>
							Too many ads, too much fake. <br />
							It’s all about data, with privacy at stake. <br />
							Too much logic, without the magic.
							<br />
							People hate ads, and that’s simply tragic!
						</p>

						<p className='mb-4'>How are we different?</p>

						<p className='mb-4'>
							We believe in convincing before we sell.
							<br />
							We want to make the world simpler, with every story we tell.
							<br />
							While others search for value, we search for meaning.
							<br />
							We are storytellers, and storytelling ain’t machining.
							<br />
						</p>
						<p className='mb-4'>
							Product - Price - Place - Promotion
							<br />
							Are The Four Ps of Marketing <br />
							We add the fifth P and that’s PURPOSE <br />
							We Question Everything! <br />
						</p>
						<p className='mb-4'>
							Welcome To 5W1H
							<br />A Creative Solutions Company
						</p>

						<Link to='/about'>
							<button className='main-button'>
								<div class='wave'></div>
								<span>
									Read More <img alt='5w1h' src={RightArrow} />
								</span>
							</button>
						</Link>
					</div>
				</div>
			</section> */}

			<section className='section-recent-works'>
				<div className='container'>
					<div className='row'>
						<div className='col-xl-10 mx-auto'>
							<header>
								<h1 className='sec-title mb-0'>Our Recent Work</h1>
							</header>
						</div>
					</div>
				</div>

				<Slider {...recentWorkSlider} className='recent-work-slider'>
					{recentWork?.length > 0 && recentWork.map((item, index) => (
						<div>
							<div className='recent-work-slide'>
								<div
									className='rw-poster'
									onClick={() => {
										handleYoutubeVideo(item.video_link);
									}}>
									<img src={BASE_URL + "/uploads/" + item.poster_image_url} />
								</div>
								<h3>{item.video_title}</h3>
							</div>
						</div>
					))}
				</Slider>


				<div>
					<ModalVideo
						autoplay
						isOpen={recentWork1}
						channel='youtube'
						videoId={getVideoId(rworkLink)}
						onClose={() => setRw1Open(false)}
					/>
					
				</div>

			
			</section>

			{/* <OurServices /> */}
			<OurServices1
				title='How We Serve'
				description='We look inward to solve brand problems and outward to solve communication issues. Our services are driven by keeping the brand purpose at the core. We use creative communications and intuitive solutions to drive business growth. '
			/>
			<TrustSlider title='Our Clients' />
			{awards.length > 0 ? (
				<section className='section-bottom container pt-5 awards'>
					<h1 className='text-center sec-title mb-5'>Awards</h1>
					<div className='row justify-content-center'>
						<div className='col-12 col-md-8 col-xl-8'>
							<div className='row mb-5 justify-content-center'>
								{awards.map((item) => (
									<div className='col-6 col-md-4 mb-4'>
										<Link to={{ pathname: item.url }} target='_blank'>
											<div className='awards-number'>
												<div className='award-no-lh'>{item.awards_no}</div>
												<div className='img-box'>
													{user.style === "App light-theme" ? (
														<img alt='5w1h' className='img-fluid' src={BASE_URL + "/uploads/" + item.light_icon} />
													) : (
														<img alt='5w1h' className='img-fluid' src={BASE_URL + "/uploads/" + item.dark_icon} />
													)}
												</div>
											</div>
											<div className='awards-title text-center'>{item.title}</div>
										</Link>
									</div>
								))}
							</div>
						</div>
						<div className='text-center'>
							<Link to='/awards'>
								<button className='main-button mx-auto'>
									<div class='wave'></div>
									<span>
										View All <img alt='5w1h' src={RightArrow} />
									</span>
								</button>
							</Link>
						</div>
					</div>
				</section>
			) : null}
			<ClientSlider title='How They Feel' />
			<MoveStuffAround />
		</div>
	);
}

export default Home;
