import Logo from "../images/logo.png";
// import Phone from "../images/icons/phone-alt-solid.svg";
// import Message from "../images/icons/envelope-solid.svg";
import Facebook from "../images/icons/facebook.svg";
import Email from "../images/icons/email.png";
import Phone1 from "../images/icons/phone.png";
import Twitter from "../images/icons/twitter.svg";
import LinkedIn from "../images/icons/linkedin.svg";
import Instagram from "../images/icons/instagram.svg";
import Youtube from "../images/icons/youtube.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="rm-footer">
      <section className="container-xl">
        <div className="row">
          <div className="col-12 col-md-5">
            <img alt="5w1h" className="mb-4 footer-logo" src={Logo} />
            <h1 className="footer-company-slogan mb-4">
              A Creative Media Advertising Agency. 
            </h1>
            <div className="content-footer">
              5W1H. Five of the W’s (Who, What, Why, When and Where) and the one
              H (How) is used as a tool to Question Everything. We believe
              asking good questions is the first step towards seeking great
              answers.
            </div>
          </div>
          <div className="col-12 col-md-3 mt-4 mt-md-0">
            <div className="d-flex">
              <div className="w-50">
                <ul className="footer-menu">
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/work">Work</Link>
                  </li>
                </ul>
              </div>
              <div className="w-50">
                <ul className="footer-menu">
                  {/* <li>
                    <Link to="/learn">Learn</Link>
                  </li> */}
                  <li>{/* <Link to="/about">Contact Us</Link> */}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-4 mt-md-0">
            <div className="contact-details">
              <div className="mb-3 d-flex align-items-center flex-wrap flex-md-nowrap">
                <img alt="5w1h" src={Email} />
                <a className="ms-2" href="mailto:kunal@5w1h.co.in">
                kunal@5w1h.co.in 
                </a>
                 
              </div>
              <div className="mb-5 d-flex align-items-center d-none d-md-block">
                <img alt="5w1h" src={Phone1} />
                <a className="ms-2" href="tel:+91 99306 73583">
                +91 99306 73583
                </a>
              </div>

              <div className="mb-5 d-flex align-items-center d-sm-block d-md-none">
                <img alt="5w1h" src={Phone1} />
                <a className="" href="tel:+91 99306 73583">
                +91 99306 73583
                </a>
                 
              </div>
              <div className="social-icon mt-md-4 mt-xl-0">
                <div>
                  <Link
                    to={{
                      pathname: "https://www.facebook.com/5w1h.co.in",
                    }}
                    target="_blank"
                  >
                    <img alt="5w1h" src={Facebook} />
                  </Link>
                </div>
                <div>
                  <Link
                    to={{
                      pathname: "https://twitter.com/5w1h_co_in",
                    }}
                    target="_blank"
                  >
                    <img alt="5w1h" src={Twitter} />
                  </Link>
                </div>
                <div>
                  <Link
                    to={{
                      pathname:
                        "https://www.linkedin.com/company/5w1hcreativesolutions/mycompany/?viewAsMember=true",
                    }}
                    target="_blank"
                  >
                    <img alt="5w1h" src={LinkedIn} />
                  </Link>
                </div>
                <div>
                  <Link
                    to={{
                      pathname:
                        "https://www.instagram.com/5w1h.co.in/?fbclid=IwAR0II_4buyknQjiPcCe9Am1hLRJJa9ng-Jvvlcy_nGRS5KJyQglBVh0S16I",
                    }}
                    target="_blank"
                  >
                    <img alt="5w1h" src={Instagram} />
                  </Link>
                </div>
                <div>
                  <Link
                    to={{
                      pathname:
                        "https://www.youtube.com/channel/UCyxPBbyejVnkV65A-1L81fw",
                    }}
                    target="_blank"
                  >
                    <img alt="5w1h" src={Youtube} />
                  </Link>
                </div>
              </div>
              <p
                className="mt-5 mb-0"
                style={{
                  fontSize: "11px",
                  color: "#bebebe",
                  fontFamily: "Work Sans",
                }}
              >
                Design By 5W1H - © 2021. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
