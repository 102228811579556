import WorkImage from "../images/projects-bg.png";
import ChevronLeft from "../images/icons/chevron-left-solid.svg";

function BackBanner() {
  return (
    <section className="container back-main">
      <div className="back-banner">
        <img className="img-fluid" src={WorkImage} />
        <div className="overlay">
          <div className="back py-4 d-flex align-items-center">
            <img className="mr-3 chev-left" width="30" src={ChevronLeft} />
            <p className="mb-0">BACK TO ALL BLOGS</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BackBanner;
