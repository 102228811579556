import React, { useContext } from "react";
import { ThemeContext } from "./ThemeContext";
// export const UserContext = createContext();

// This context provider is passed to any component requiring the context
let ThemeService = ({ children }) => {
  const user = useContext(ThemeContext);

  return <div className={user.style}>{children}</div>;
};

export default ThemeService;
