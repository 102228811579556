
// import React, { useState } from "react";
import { Navbar, Nav, Form } from "react-bootstrap";
import Logo from "../images/logo.png";
// import LightTheme from "../images/lightTheme.png";
import LogoHome from "../images/logo-original.png";
import CancelRed from "../images/icons/cancel-red.png";
import CancelBlue from "../images/icons/cancel-blue.png";
import ArrowRight from "../images/icons/arrow-right-solid.svg";
import darklogo from "../images/Logo_White.png";
import { Link } from "react-router-dom";
import Cookies from "../images/cookies.png";
import Close from "../images/icons/cancel.png";
import Facebook from "../images/icons/facebook.svg";
import Twitter from "../images/icons/twitter.svg";
import LinkedIn from "../images/icons/linkedin.svg";
import Instagram from "../images/icons/instagram.svg";
import Toggle from "../components/Toggler.js";
import axios from "../axios";

import ToastContainer, { toast } from 'react-light-toast';

// import {ToastContainer, toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import Youtube from "../images/icons/youtube.svg";

// import "animate.css"
import { useDarkLightMode } from "../components/useDarkLightMode";
import { useCallback, useEffect, useState } from "react";

// import React from 'react';



// import React, { useRef } from "react";

import { useHistory } from "react-router-dom";

function Header() {

	// toast.configure()

  const setcookies = localStorage.getItem("setcookies");


  const [cookievisible, setCookievisible] = useState(
    setcookies ? (setcookies === "1" ? false : true) : true
  );
  const [theme, themeToggler] = useDarkLightMode();
  const [menu, setMenu] = useState(
    "justify-content-end navbar-collapse collapse"
  );
  const [showContact, setshowContact] = useState();

  // const [answer, setAnswer] = useState([]);sblength
  const [current, setCurrent] = useState(0);
  const [currentPos, setCurrentPos] = useState(0);
  const [sblength] = useState(3);
  const [formID, setFormID] = useState(1);
  const [sbQuestion, SetsbQuestion] = useState("");
  const [sbOptions, SetsbOptions] = useState([]);
  const [selectedItems , setSelectedItems] = useState([])

  const [firstName, setFirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [phone, setphone] = useState("")
  const [email, setemail] = useState("")
  const [role, setrole] = useState("")
  const [joiningDate, setjoiningDate] = useState("")
  const [company, setcompany] = useState("")
//   const [deadline, setdeadline] = useState("")
  const [description, setdescription] = useState("")
  const [Deadline, setDeadline] = useState("")
  const [toastMassage, setToastMassage] = useState(false)
 

//   console.log(firstName , "firstName >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")

  
//   console.log(selectedItems.toString(), "selectedItems >>>>>>>>>>>>>>>>>>")

//   console.log(current,  "current >>>>>>>>>>>>>>")
//   console.log(currentPos, "currentPos >>>>>>>>>>>>>>")
//   console.log(sblength, "sublength >>>>>>>>>>>>>>>>>>>>>>")
//   console.log(sbQuestion, "sbQuestion >>>>>>>>>>>>>>>>>>>>>>>>")
//   console.log(sbOptions, "sbOptions >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")

  const [contact] = useState([
    {
      question: ["Knock Knock, Who is there?<br/>How can we help you?"],
      options: [
        { title: "Start a project", pos: 1 },
        { title: "Join the team", pos: 2 },
        { title: "Drop a quick word", pos: 0, end: 3 },
      ],
      done: true,
    },
    {
      question: [
        "Listen, together, you and I can rule the planet.",
        "Hey! I smell a collaboration, Do you?",
      ],
      options: [
        { title: "Branding", pos: 3, end: false },
        { title: "Performance", pos: 3, end: false },
        { title: "Both", pos: 3, end: false },
      ],
    },
    {
      question: [
        "Change is constant! Yes, it's time to switch that job, too! <br/>Apply Now.",
        "Together we can rock the world! We are waiting for your application.",
        "Team player? Join us now and enjoy working with the team.",
      ],
      options: [
        { title: "Design", pos: 5 },
        { title: "Operations", pos: 5 },
        { title: "Copywriting", pos: 5 },
        { title: "Creative Strategy", pos: 5 },
      ],
    },
    {
      question: [
        "Listen, together, you and I can rule the planet.",
        "Hey! I smell a collaboration, Do you?",
      ],
      options: [
        { title: "Strategy & Planning", pos: 4, end: false },
        { title: "Design & Development", pos: 4, end: false },
        { title: "Media & Commerce", pos: 4, end: false },
        { title: "Film Making & Operation", pos: 4, end: false },
      ],
    },
    {
      question: ["We make you happy, you make us happy."],
      options: [
        { title: "Less than 10 Lacs", pos: 0, end: 1 },
        { title: "10-50 Lacs", pos: 0, end: 1 },
        { title: "50 Lacs - 1 Cr", pos: 0, end: 1 },
        { title: "1 Cr+", pos: 0, end: 1 },
      ],
    },
    {
      question: [
        "If you are a people's person then you have to give this role a try without thinking why?",
        "What are you waiting for is creativity flows through you? Apply now.",
        "The number is your game? Handle our media and buck up your game.",
      ],
      options: [
        { title: "Fresher", pos: 0, end: 2 },
        { title: "<2Years", pos: 0, end: 2 },
        { title: "2-5 Years", pos: 0, end: 2 },
        { title: ">5Years", pos: 0, end: 2 },
      ],
    },
  ]);

  const handleClick = (item, index) => () => {
    console.log(index, "Item", item);

	setSelectedItems([...selectedItems, item.title])



    document.querySelector(".contact-option-btn").className =
      "contact-option-btn";
    // setCurrent(current + 1);
    setTimeout(function () {
      document.querySelector(".head-title").className =
        "text-center text-white mb-5  head-title";
      console.log("item.pos", item.pos, "Current", current, current < sblength);
      if (item.pos > 0) {
        setCurrentPos(item.pos);
        setTimeout(function () {
          let currentData = contact[item.pos];
          console.log(
            currentData,
            "currentData",
            currentPos,
            current < sblength
          );
          let random = Math.floor(Math.random() * currentData.question.length);
          console.log(random, "random");
          SetsbQuestion(currentData.question[random]);
          SetsbOptions(currentData.options);
          document.querySelector(".head-title").className =
            "text-center text-white mb-5  head-title head-title-show";
          setTimeout(function () {
            document.querySelector(".contact-option-btn").className =
              "contact-option-btn contact-option-btn-show";
          }, 1000);
        }, 800);
      } else {
        setFormID(item.end);
        setTimeout(function () {
          setCurrent(4);
          // document.querySelector(".cu-form").className = "cu-form cu-form-sb";
        }, 800);
      }
    }, 800);
  };

  const handleCollapse = () => {
    menu === "justify-content-end navbar-collapse collapse"
      ? setMenu("justify-content-end navbar-collapse collapse show")
      : setMenu("justify-content-end navbar-collapse collapse");
  };

  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      console.log(`You changed the page to: ${location.pathname}`);

      if (!showContact) {
        if (window.location.pathname === "/") {
          document.querySelector(".sbnavtwo").className = "sbnavtwo d-none";
          document.querySelector(".sbnavone").className = "sbnavone home-logo";
          document.querySelector(".sbnav").className =
            "pb-0 sbnav navbar navbar-expand-md bg-trans navbar-light";
        } else {
          document.querySelector(".sbnav").className =
            "pb-0 sbnav navbar navbar-expand-md white-bg navbar-light";
          document.querySelector(".sbnavone").className =
            "sbnavone d-none home-logo";
          document.querySelector(".sbnavtwo").className = "sbnavtwo";
        }
      } else {
        document.querySelector(".sbnavtwo").className = "sbnavtwo d-none";
        document.querySelector(".sbnavone").className = "sbnavone home-logo";
        document.querySelector(".sbnav").className =
          "pb-0 sbnav navbar navbar-expand-md bg-trans navbar-light";
      }
    });
  }, [history]);

  const handleScroll = useCallback((event) => {
    // console.log(window.scrollY);
    if (window.location.pathname === "/") {
      if (window.scrollY > 680) {
        document.querySelector(".sbnav").className =
          "pb-0 sbnav navbar navbar-expand-md white-bg navbar-light";
        document.querySelector(".sbnavone").className =
          "sbnavone d-none home-logo";
        document.querySelector(".sbnavtwo").className = "sbnavtwo";
      } else {
        document.querySelector(".sbnavtwo").className = "sbnavtwo d-none";
        document.querySelector(".sbnavone").className = "sbnavone home-logo";
        document.querySelector(".sbnav").className =
          "pb-0 sbnav navbar navbar-expand-md bg-trans navbar-light";
      }
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    setTimeout(function () {
      if (window.location.pathname !== "/") {
        console.log("Hello");
        console.log(window.location.pathname, "I am here");
        document.querySelector(".sbnav").className =
          "pb-0 sbnav navbar navbar-expand-md white-bg navbar-light";
        document.querySelector(".sbnavone").className =
          "sbnavone d-none home-logo";
        document.querySelector(".sbnavtwo").className = "sbnavtwo";
      }
    }, 1000);
  });

  const submitFinalFrom = () => {

	

	let path = "contact-us"

	console.log("HIIIIIIIIIIIIIIII >>>>>>>>>>>>>>")

	axios.post(path, {
		first_name : firstName,
		last_name : lastName,
		user_phone : phone,
		user_email : email,
		user_role : role,
		joining_date : joiningDate,
		company : company,
		description : description,
		deadline : Deadline,
		user_selectedItems: selectedItems.toString() 
	}).then((res)=>{

		toast.info('Feedback submitted SuccessFully')

		// toast('Hello Geeks 4',
		// {position: toast.POSITION.BOTTOM_LEFT})
		document.getElementById("contact-us-btn").click();
		// console.log(res, "res >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
	})
  }

  // const [theme, setTheme] = useState("light");

  // const themeToggler = () => {
  //   theme === "light" ? setTheme("dark") : setTheme("light");
  // };

  return (
		<div>
			<div>     <ToastContainer />      </div>
			<div>



			{cookievisible ? (
				<div className='d-none d-md-block'>
					<div className='cookies-main d-flex'>
						<img alt='cookies' className='cookie-img' src={Cookies} />
						<div className='cookie-content'>
							<div className='text-dark'>This website uses cookies.</div>
							<div>
								<a style={{ color: "#FA3535" }} href='#'>
									Find out more
								</a>
							</div>
						</div>
						<img
							alt='setcookies'
							style={{ cursor: "pointer" }}
							width='20'
							src={Close}
							onClick={() => {
								setCookievisible(false);
								console.log("setcookies");
								localStorage.setItem("setcookies", 1);
							}}
						/>
					</div>
				</div>
			) : null}
			<button
				onClick={() => {
					console.log("I am clicked");
					setCurrent(0);
					document.querySelector(".contact-us-btn").className === "btn btn-primary contact-us-btn"
						? (document.querySelector(".contact-us-btn").className = "btn btn-primary contact-us-btn active")
						: (document.querySelector(".contact-us-btn").className = "btn btn-primary contact-us-btn");

					if (!showContact) {
						console.log("show");
						setTimeout(function () {
							console.log("In");
							document.querySelector(".head-title").className =
								"text-center text-white mb-5  head-title head-title-show";
							setTimeout(function () {
								document.querySelector(".contact-option-btn").className = "contact-option-btn contact-option-btn-show";
							}, 1000);
						}, 800);

						document.getElementById("contact-us-btn").style.zIndex = "999999";
						//contact-us-btn
						document.querySelector(".sbnav").className = "pb-0 sbnav navbar navbar-expand-md white-bg navbar-light";
						document.querySelector(".sbnavone").className = "sbnavone d-none home-logo";
						document.querySelector(".sbnavtwo").className = "sbnavtwo";
					} else {
						console.log("hide");
						if (document.querySelector(".head-title")) {
							document.querySelector(".head-title").className = "text-center text-white mb-5  head-title";
						}
						if (document.querySelector(".contact-option-btn")) {
							document.querySelector(".contact-option-btn").className = "contact-option-btn";
						}

						document.getElementById("contact-us-btn").style.zIndex = "99";
						if (window.scrollY > 680) {
							document.querySelector(".sbnav").className = "pb-0 sbnav navbar navbar-expand-md white-bg navbar-light";
							document.querySelector(".sbnavone").className = "sbnavone d-none home-logo";
							document.querySelector(".sbnavtwo").className = "sbnavtwo";
						} else {
							document.querySelector(".sbnavtwo").className = "sbnavtwo d-none";
							document.querySelector(".sbnavone").className = "sbnavone home-logo";
							document.querySelector(".sbnav").className = "pb-0 sbnav navbar navbar-expand-md bg-trans navbar-light";
						}
					}
					//-----
					//current question and options
					let currentData = contact[0];
					SetsbQuestion(currentData.question[Math.floor(Math.random() * currentData.question.length)]);
					SetsbOptions(currentData.options);

					//
					setshowContact(!showContact);
				}}
				id='contact-us-btn'
				type='button'
				className='btn btn-primary contact-us-btn'
				data-bs-toggle='modal'
				data-bs-target='#exampleModal'>
				<div style={{ fontWeight: 500 }}>
					Contact <br /> Us
				</div>
				<img alt='5w1h' src={theme === "App dark-theme" ? CancelBlue : CancelRed} />
			</button>
			<div
				className='modal fade contact-us-modal'
				id='exampleModal'
				tabIndex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'>
				<div className='modal-dialog modal-fullscreen'>
					<div className='modal-content'>
						<div className='modal-body'>
							<div className='cu-modal d-block d-md-flex justify-content-center align-items-center'>
								{current < sblength ? (
									<div className='contact-bottom-to-top contact-bottom-to-top-sb'>
										<div className='row justify-content-center'>
											<div className='col-md-12'>
												<p
													className='text-center text-white mb-5 head-title'
													dangerouslySetInnerHTML={{
														__html: sbQuestion,
													}}></p>

												<div className='contact-option-btn'>
													<div className='d-block d-md-flex justify-content-between align-items-center'>
														{sbOptions.map((item, index) => (
															<button key={index} className='option-button' onClick={handleClick(item, index)}>
																{item.title}
															</button>
														))}
													</div>
												</div>
											</div>
										</div>
									</div>
								) : (
									<div className='cu-form'>
										<div className='row justify-content-center'>
											<div className='col-md-12'>
												<p className='mb-4 text-white text-center'>Contact Us</p>
												{/* form1 */}
												{formID == 1 ? (
													<Form>
														<div className='row justify-content-center'>
															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setFirstName(e.target.value)} type='text' placeholder='First Name' required />
																</Form.Group>
															</div>
															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setlastName(e.target.value)} type='text' placeholder='Last Name' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setphone(e.target.value)} type='text' placeholder='Phone' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setemail(e.target.value)} type='email' placeholder='E-mail' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setcompany(e.target.value)} type='text' placeholder='Company' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setDeadline(e.target.value)} type='text' placeholder='Deadline' required />
																</Form.Group>
															</div>

															<div className='col-md-12 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setdescription(e.target.value)} as='textarea' rows={3} placeholder='Description' required />
																</Form.Group>
															</div>

															{/* <div className='col-md-12 mb-4'>
																<Form.Group
																	className='d-flex align-items-center justify-content-between'
																	controlId='formBasicEmail'>
																	<Form.Label className='mb-0' style={{ color: "#FFFFFF" }}>
																		Upload Brief
																	</Form.Label>
																	<Form.Control className='text-white' type='file' placeholder='Brief' required />
																</Form.Group>
															</div> */}

															<div className='col-md-12'>
																<button
																	className='main-button mx-auto'
																	type='button'
																	onClick={()=>submitFinalFrom()}>
																	<div class='wave'></div>
																	<span>Submit</span>
																</button>
															</div>
														</div>
													</Form>
												) : null}

												{formID == 2 ? (
													<Form>
														<div className='row justify-content-center'>
															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setFirstName(e.target.value)} type='text' placeholder='First Name' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setlastName(e.target.value)} type='text' placeholder='Last Name' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setphone(e.target.value)} type='text' placeholder='Phone' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setemail(e.target.value)} type='email' placeholder='E-mail' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setrole(e.target.value)} type='text' placeholder='Role' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setjoiningDate(e.target.value)} type='text' placeholder='Joining Date' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setdescription(e.target.value)} as='textarea' rows={3} placeholder='Description' required />
																</Form.Group>
															</div>

															{/* <div className='col-md-12 mb-4'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Label style={{ color: "#FFFFFF" }}>Upload Resume</Form.Label>
																	<Form.Control type='file' placeholder='Brief' required />
																</Form.Group>
															  </div> */}
															  
															<div className='col-md-12'>
																<button
																	className='main-button mx-auto'
																	type='button'
																	onClick={()=>submitFinalFrom()}>
																	<div class='wave'></div>
																	<span>Submit</span>
																</button>
															</div>
														</div>
													</Form>
												) : null}

												{formID == 3 ? (
													<Form>
														<div className='row justify-content-center'>
															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setFirstName(e.target.value)} type='text' placeholder='First Name' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setlastName(e.target.value)} type='text' placeholder='Last Name' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setphone(e.target.value)} type='text' placeholder='Phone' required />
																</Form.Group>
															</div>

															<div className='col-md-6 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setemail(e.target.value)} type='email' placeholder='E-mail' required />
																</Form.Group>
															</div>

															<div className='col-md-12 mb-3'>
																<Form.Group controlId='formBasicEmail'>
																	<Form.Control onChange={(e)=>setdescription(e.target.value)} as='textarea' rows={3} placeholder='Description' required />
																</Form.Group>
															</div>

															{/* <div className='col-md-12 mb-4'>
																<Form.Group
																	className='d-flex align-items-center justify-content-between'
																	controlId='formBasicEmail'>
																	<Form.Label className='mb-0' style={{ color: "#FFFFFF" }}>
																		Upload Brief
																	</Form.Label>
																	<Form.Control className='text-white' type='file' placeholder='Brief' required />
																</Form.Group>
															</div> */}

															<div className='col-md-12'>
																<button
																	className='main-button mx-auto'
																	type='button'
																	onClick={()=>submitFinalFrom()}>
																	<div class='wave'></div>
																	<span>Submit</span>
																</button>
															</div>
														</div>
													</Form>
												) : null}

												{/* form 1 */}
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='rm-header d-none d-md-block'>
				<Navbar className='pb-0 sbnav bg-trans' expand='md'>
					<div className='pZero container'>
						<Navbar.Brand as={Link} to='/'>
							<img alt='5w1h' className='sbnavone home-logo' src={Logo} />

							<img
								alt='5w1h MOBILE LOGO'
								className='d-none sbnavtwo'
								src={theme === "App dark-theme" ? darklogo : LogoHome}
							/>
						</Navbar.Brand>
						<div className='d-block d-md-none'>
							<Toggle theme={theme} toggleTheme={themeToggler} />
						</div>
						<div
							className='d-block d-md-none menu-btn'
							onClick={() =>
								menu === "justify-content-end navbar-collapse collapse"
									? setMenu("justify-content-end navbar-collapse collapse show")
									: setMenu("justify-content-end navbar-collapse collapse")
							}>
							Menu
						</div>
						<Navbar.Toggle aria-controls='navbarScroll1' />
						<Navbar.Collapse className={menu} id='navbarScroll1'>
							<div className='d-none d-md-block'>
								<Nav className='mr-auto my-md-2 my-lg-0 align-items-center' style={{ maxHeight: "100px" }} navbarScroll>
									<Nav.Link className={window.location.pathname === "/about" ? "active" : ""} as={Link} to='/about'>
										About
									</Nav.Link>
									<Nav.Link
										className={window.location.pathname === "/services" ? "active" : ""}
										as={Link}
										to='/services'>
										Services
									</Nav.Link>
									<Nav.Link className={window.location.pathname === "/work" ? "active" : ""} as={Link} to='/work'>
										Work
									</Nav.Link>
									<Nav.Link>
										<Toggle theme={theme} toggleTheme={themeToggler} />
									</Nav.Link>
								</Nav>
							</div>

							<div className='d-block d-md-none'>
								<Nav className='mr-auto my-md-2 my-lg-0' navbarScroll>
									<Nav.Link className={window.location.pathname === "/about" ? "active" : ""} as={Link} to='/about'>
										About <img alt='5w1h' className='img-fluid' src={ArrowRight} />
									</Nav.Link>
									<Nav.Link
										className={window.location.pathname === "/services" ? "active" : ""}
										as={Link}
										to='/services'>
										Services <img alt='5w1h' className='img-fluid' src={ArrowRight} />
									</Nav.Link>
									<Nav.Link className={window.location.pathname === "/work" ? "active" : ""} as={Link} to='/work'>
										Work <img alt='5w1h' className='img-fluid' src={ArrowRight} />
									</Nav.Link>
									{/* <Nav.Link
                    className={
                      window.location.pathname === "/learn" ? "active" : ""
                    }
                    as={Link}
                    to="/learn"
                  >
                    Learn{" "}
                    <img alt="5w1h" className="img-fluid" src={ArrowRight} />
                  </Nav.Link> */}
									{/* <Nav.Link href="#action5">
                <ThemeProvider
                  theme={theme === "light" ? lightTheme : darkTheme}
                >
                  <GlobalStyles />
                  <StyledApp>
                      helloworld
                    <Button onClick={() => themeToggler()}>Change Theme</Button>
                  </StyledApp>
                </ThemeProvider>
              </Nav.Link> */}
									<Nav.Link className='w-100'>
										<div
											className='w-100'
											style={{
												borderTop: "1px solid #ffffff",
												borderBottom: "1px solid #ffffff",
											}}>
											<div>Contact</div>
											<div style={{ fontSize: "18px" }} className='mb-3 d-flex align-items-center'>
												{" "}
												+91 773 847 8982 | +91 993 067 3583{" "}
											</div>
										</div>
									</Nav.Link>
									<div className='nav-social-icon my-4'>
										<div className='social-icon'>
											<div>
												<Link
													to={{
														pathname: "https://www.facebook.com/5w1h.co.in",
													}}
													target='_blank'>
													<img alt='5w1h' src={Facebook} />
												</Link>
											</div>
											<div>
												<Link
													to={{
														pathname: "https://twitter.com/5w1h_co_in",
													}}
													target='_blank'>
													<img alt='5w1h' src={Twitter} />
												</Link>
											</div>
											<div>
												<Link
													to={{
														pathname:
															"https://www.linkedin.com/company/5w1hcreativesolutions/mycompany/?viewAsMember=true",
													}}
													target='_blank'>
													<img alt='5w1h' src={LinkedIn} />
												</Link>
											</div>
											<div>
												<Link
													to={{
														pathname:
															"https://www.instagram.com/5w1h.co.in/?fbclid=IwAR0II_4buyknQjiPcCe9Am1hLRJJa9ng-Jvvlcy_nGRS5KJyQglBVh0S16I",
													}}
													target='_blank'>
													<img alt='5w1h' src={Instagram} />
												</Link>
											</div>
											{/* <div>
                                  <a href=""><img alt="5w1h" src={Youtube} /></a>
                              </div> */}
										</div>
									</div>
									<div className='cookies-navbar'>
										<div className='cookies-navbar-main d-flex'>
											<img alt='5w1h' className='cookie-navbar-img' src={Cookies} />
											<div className='cookie-navbar-content'>
												<div className='text-dark'>This website uses cookies.</div>
												<div>
													<a style={{ color: "#FA3535" }} href=''>
														Find out more
													</a>
												</div>
											</div>
											<img
												alt='5w1h'
												style={{ cursor: "pointer" }}
												width='20'
												src={Close}
												onClick={() => setCookievisible(false)}
											/>
										</div>
									</div>
								</Nav>
							</div>
						</Navbar.Collapse>
					</div>
				</Navbar>
			</div>

			<div className='rm-header d-md-none d-sm-block'>
				<Navbar className='pb-0 sbnav white-bg' expand='md'>
					<div className='pZero container '>
						<Navbar.Brand as={Link} to='/'>
							<img alt={theme} className='mobilelogo' src={theme === "App dark-theme" ? darklogo : LogoHome} />
						</Navbar.Brand>
						<div className='d-block d-md-none'>
							<Toggle theme={theme} toggleTheme={themeToggler} />
						</div>

						<div
							className='d-block d-md-none menu-btn'
							onClick={() =>
								menu === "justify-content-end navbar-collapse collapse"
									? setMenu("justify-content-end navbar-collapse collapse show")
									: setMenu("justify-content-end navbar-collapse collapse")
							}>
							Menu
						</div>
						<Navbar.Toggle aria-controls='navbarScroll' />
						<Navbar.Collapse className={menu} id='navbarScroll'>
							<div className='d-none d-md-block'>
								<Nav className='mr-auto my-md-2 my-lg-0' style={{ maxHeight: "100px" }} navbarScroll>
									<Nav.Link
										onClick={() => handleCollapse()}
										className={window.location.pathname === "/about" ? "active" : ""}
										as={Link}
										to='/about'>
										About
									</Nav.Link>
									<Nav.Link
										className={window.location.pathname === "/services" ? "active" : ""}
										as={Link}
										to='/services'>
										Services
									</Nav.Link>
									<Nav.Link className={window.location.pathname === "/work" ? "active" : ""} as={Link} to='/work'>
										Work
									</Nav.Link>
									{/* <Nav.Link
                    className={
                      window.location.pathname === "/learn" ? "active" : ""
                    }
                    as={Link}
                    to="/learn"
                  >
                    Learn
                  </Nav.Link> */}
									<Nav.Link>
										<Toggle theme={theme} toggleTheme={themeToggler} />
									</Nav.Link>
								</Nav>
							</div>

							<div className='d-block d-md-none mobilemenu'>
								<Nav className='mr-auto my-md-2 my-lg-0' navbarScroll>
									<Nav.Link
										onClick={() => handleCollapse()}
										className={window.location.pathname === "/about" ? "active" : ""}
										as={Link}
										to='/about'>
										About <img alt='5w1h' className='img-fluid' src={ArrowRight} />
									</Nav.Link>
									<Nav.Link
										onClick={() => handleCollapse()}
										className={window.location.pathname === "/services" ? "active" : ""}
										as={Link}
										to='/services'>
										Services <img alt='5w1h' className='img-fluid' src={ArrowRight} />
									</Nav.Link>
									<Nav.Link
										onClick={() => handleCollapse()}
										className={window.location.pathname === "/work" ? "active" : ""}
										as={Link}
										to='/work'>
										Work <img alt='5w1h' className='img-fluid' src={ArrowRight} />
									</Nav.Link>
									{/* <Nav.Link
                    onClick={() => handleCollapse()}
                    className={
                      window.location.pathname === "/learn" ? "active" : ""
                    }
                    as={Link}
                    to="/learn"
                  >
                    Learn{" "}
                    <img alt="5w1h" className="img-fluid" src={ArrowRight} />
                  </Nav.Link> */}
									{/* <Nav.Link href="#action5">
                <ThemeProvider
                  theme={theme === "light" ? lightTheme : darkTheme}
                >
                  <GlobalStyles />
                  <StyledApp>
                      helloworld
                    <Button onClick={() => themeToggler()}>Change Theme</Button>
                  </StyledApp>
                </ThemeProvider>
              </Nav.Link> */}
										<div
											className='w-100 nav-link d-block'
											style={{
												borderTop: "1px solid #ffffff",
												borderBottom: "1px solid #ffffff",
												color: "#fffff",
											}}>
											<div>Contact</div>
											<div style={{ fontSize: "18px" }} className='mb-3 d-flex align-items-center'>
												<a className='ms-2 sba' href='tel:+91 99306 73583'>
													+91 99306 73583
												</a>
											</div>
										</div>
									<div className='nav-social-icon my-4'>
										<div className='social-icon sbsocial-icon'>
											<div>
												<a href='https://www.facebook.com/5w1h.co.in' target='_blank'>
													<img alt='5w1h' src={Facebook} />
												</a>
											</div>
											<div>
												<a href='https://twitter.com/5w1h_co_in' target='_blank'>
													<img alt='5w1h' src={Twitter} />
												</a>
											</div>
											<div>
												<a
													href='https://www.linkedin.com/company/5w1hcreativesolutions/mycompany/?viewAsMember=true'
													target='_blank'>
													<img alt='5w1h' src={LinkedIn} />
												</a>
											</div>
											<div>
												<a
													href='https://www.instagram.com/5w1h.co.in/?fbclid=IwAR0II_4buyknQjiPcCe9Am1hLRJJa9ng-Jvvlcy_nGRS5KJyQglBVh0S16I'
													target='_blank'>
													<img alt='Instagram' src={Instagram} />
												</a>
											</div>
											<div>
												<a href='https://www.youtube.com/channel/UCyxPBbyejVnkV65A-1L81fw' target='_blank'>
													<img alt='5w1h' alt='5w1h' src={Youtube} />
												</a>
											</div>
										</div>
									</div>
									<div className='cookies-navbar'>
										<div className='cookies-navbar-main d-flex'>
											<img alt='5w1h' className='cookie-navbar-img' src={Cookies} />
											<div className='cookie-navbar-content'>
												<div className='text-dark'>This website uses cookies.</div>
												<div>
													<a style={{ color: "#FA3535" }} href=''>
														Find out more
													</a>
												</div>
											</div>
											<img
												alt='5w1h'
												style={{ cursor: "pointer" }}
												width='20'
												src={Close}
												onClick={() => setCookievisible(false)}
											/>
										</div>
									</div>
								</Nav>
							</div>
						</Navbar.Collapse>
					</div>
				</Navbar>
			</div>
		</div>
		</div>
	);
}

export default Header;
