import Slider from "react-slick";
import React, { useState, useEffect } from "react";
import axios from "../axios";
import { Link } from "react-router-dom";
import { BASE_URL } from "../config";
import GreyBox from "../images/greybox.png";
function TrustSlider(props) {
  const [client, setclient] = useState([]);

  useEffect(() => {
    if (client.length > 0) {
      return;
    }
    let path = "getclient";
    return axios
      .get(path)
      .then((data) => {
        // console.log("data.data.data", data.data.data);
        setclient(data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [client]);

  var Trustsettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
		<>
			{client.length > 0 ? (
				<section className='section-bottom container pt-5 awards'>
					<div className='row justify-content-center'>
						<div className='col-10'>
							<h1 className='text-center sec-title mb-2'>{props.title}</h1>
							<div className='row justify-content-center'>
								{client.map((item, index) => (
									<div className='col-md-2 col-4 align-self-center text-center  client-logo' key={index}>
										<Link to='' target='_blank'>
											<img alt={item.brand_name} src={BASE_URL + "/uploads/" + item.logo}></img>
										</Link>
									</div>
								))}
							</div>
						</div>
					</div>
				</section>
			) : null}
		</>
	);
}

export default TrustSlider;
