// import Banner from "./Banner";
import OurServices from "./OurServices";
import OurServices1 from "../components/OurServices1";
// import SP from "../images/sp.svg";
// // import DD from "../images/dd.svg";
import WorkShowcaseSlider from "./WorkShowcaseSlider";
// import TopContentBanner from "./TopContentBanner";
// import Slider from "react-slick";

function Services() {
  // var OurHistorySlider = {
  //   arrows: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  return (
    <div style={{ paddingTop: "100px" }}>
      <OurServices />
      <OurServices1
        title="How We Serve"
        // description="We look inward to solve brand problems and outward to solve communication issues. Our services are driven by keeping the brand purpose at the core. We use creative communications and intuitive solutions to drive business growth. "
      />

      <WorkShowcaseSlider />
    </div>
  );
}

export default Services;
